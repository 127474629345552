import { CloudArrowDownIcon, ShieldCheckIcon, UsersIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { connect } from "react-redux";
import { getAuthorizedUser } from "src/actions/auth";
import PrivacyAndSecurity from "src/components/Activity/PrivacyAndSecurity";
import Tabs from "src/components/Shared/Tabs/Tabs";
import { H1 } from "src/components/Shared/Text/Headers";
import ImportUsers from "src/components/Users/Add/ImportUsers/ImportUsers";
import UsersList from "src/components/Users/UsersList";

const Users = ({ ...props }) => {
  useEffect(() => {
    getAuthorizedUser();
  }, []);
  const tabs = [
    {
      name: "User List",
      icon: UsersIcon,
      component: (
        <UsersList
          title="User"
          filterUserType={["user", "editor"]}
        />
      ),
    },
    { name: "Privacy & Security (BETA)", icon: ShieldCheckIcon, component: <PrivacyAndSecurity />, hidden: props?.user?.type !== "admin" },
    { name: "Import Users", icon: CloudArrowDownIcon, component: <ImportUsers />, hidden: props?.user?.type !== "admin" },
  ];

  return (
    <>
      <H1>Users</H1>
      <Tabs tabs={tabs} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, { getAuthorizedUser })(Users);
