import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { deleteActivityData, getActivity } from "src/actions/activity";
import { getPages } from "src/actions/page";
import ActivityEntry from "src/components/Activity/ActivityEntry";
import DateSearch from "src/components/Activity/DateSearch";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import ButtonExportContainer from "src/components/Shared/Buttons/ButtonExportContainer";
import Modal from "src/components/Shared/Modal";
import Table from "src/components/Shared/Table/Table";
import TRHeader from "src/components/Shared/Table/TRHeader";
import { utils, writeFile } from "xlsx";
import TableOptions from "src/components/Shared/TableOptions";
import MultiSelectObjectFilter from "src/components/Shared/Table/MultiSelectObjectFilter";
import SortOrder from "src/components/Shared/Table/SortOrder";
import { apiRequest } from "src/async/apiUtils";
import useFetch from "src/hooks/useFetch";

const ActivityList = ({ props }) => {
  const activityTypes = [
    { key: "PAGE_VIEW", value: "Page View" },
    { key: "LOGIN", value: "Logins/Authentication" },
  ];
  const [sortDirection, setSortDirection] = useState("ASC");

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [startDate, setStateDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Filters
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedActivityTypes, setSelectedActivityTypes] = useState([]);
  const [selectedPages, setSelectedPages] = useState([]);
  const [exportType, setExportType] = useState("xls");
  const [deleteId, setDeleteId] = useState(null);

  const handleSelectedItems = (itemsArray, setSelected) => {
    let updatedSelected = itemsArray.reduce((filteredItemsArray, item) => (item.selected ? [...filteredItemsArray, item.key] : filteredItemsArray), []);
    setSelected(updatedSelected);
    setOffset(0);
  };

  const handlePaginationChange = ({ limit, offset }) => {
    setLimit(limit);
    setOffset(offset);
  };

  const {
    response: { data: pages },
  } = useFetch(`/pages/list`, { query: {}, data: { sort_direction: "ASC" } });

  const {
    response: { data: users },
  } = useFetch(`/users/list`, { query: {}, data: { sort_direction: "ASC" } });

  const {
    response: { data: activities, meta: activitiesMeta },
    status: { done: activitiesLoaded },
    refreshData: refreshActivities,
  } = useFetch(`/list-activity`, {
    method: "PUT",
    query: {
      limit: limit,
      offset: offset * limit,
      start_date: startDate,
      end_date: endDate,
      sort_direction: sortDirection,
    },
    data: {
      users: selectedUsers,
      pages: selectedPages,
      activity_types: selectedActivityTypes,
    },
  });

  useEffect(() => {
    refreshActivities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, startDate, endDate, selectedUsers, selectedActivityTypes, selectedPages, sortDirection]);

  const removeDel = async (e) => {
    e.preventDefault();

    try {
      const message = await props.deleteActivityData(deleteId);
      setDeleteId(null);
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const formatMemberDate = (date) => {
    if (!date) return "";

    return moment(new Date(date)).format("LLL");
  };

  const exportData = async () => {
    const activityParams = {
      start_date: startDate,
      end_date: endDate,
      sort_direction: sortDirection,
    };
    const activityBody = {
      users: selectedUsers,
      pages: selectedPages,
      activity_types: selectedActivityTypes,
    };
    try {
      const { data: responseData } = await apiRequest("put", "/list-activity", { body: activityBody, queries: activityParams }, {}, true);
      let csvData = [];
      if (responseData.data.length > 0) {
        for (let index = 0; index < responseData.data.length; index++) {
          const element = responseData.data[index];
          let activity_types = element?.type;
          let typeIndex = activityTypes.findIndex((ty) => ty.key === element?.type);
          if (typeIndex !== -1) {
            activity_types = activityTypes[typeIndex].value;
          }
          csvData.push({
            Email: element?.user_id?.email,
            Name: element?.user_id?.name,
            "Activity Type": activity_types,
            Workspace: element?.data?.workspace_id?.name,
            "Page Accessed": element?.data?.page_id?.name,
            Date: formatMemberDate(element?.created_at),
          });
        }
      }

      const headings = [["Email", "Name", "Activity Type", "Workspace", "Page Accessed", "Date"]];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, csvData, { origin: "A2", skipHeader: true });
      utils.book_append_sheet(wb, ws, "Report");
      if (exportType === "xls") {
        writeFile(wb, "Activity Report.xls");
      } else {
        writeFile(wb, "Activity Report.csv");
      }
    } catch (error) {
      // console.dir(error.message);
    }
  };

  return (
    <>
      <TableOptions
        leftContent={
          <DateSearch
            setStateDate={setStateDate}
            setEndDate={setEndDate}
          />
        }
      />
      <Table
        tableHeader={
          <TRHeader>
            <th
              scope="col"
              className="rounded-l-xl py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              <div className="flex items-center gap-x-1">
                <p>Name</p>
                <div className="flex">
                  <MultiSelectObjectFilter
                    filterSelect={true}
                    defaultOptions={users?.map((user) => {
                      return { key: user._id, value: user.name, selected: selectedUsers.includes(user._id), object: user };
                    })}
                    searchableFields={["name", "email"]}
                    onChange={(e) => {
                      handleSelectedItems(e, setSelectedUsers);
                    }}
                  />
                </div>
              </div>
            </th>
            <th
              scope="col"
              className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              <div className="flex items-center gap-x-1">
                <p>Activity Type</p>
                <div className="flex">
                  <MultiSelectObjectFilter
                    filterSelect={true}
                    defaultOptions={activityTypes.map((type) => {
                      return { key: type.key, value: type.value, selected: selectedActivityTypes.includes(type.key), object: { _id: type.key, ...type } };
                    })}
                    onChange={(e) => {
                      handleSelectedItems(e, setSelectedActivityTypes);
                    }}
                  />
                </div>
              </div>
            </th>
            <th
              scope="col"
              className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              <div className="flex items-center gap-x-1">
                <p>
                  {" "}
                  Page <span className="md:inline hidden">Accessed</span>
                </p>
                <div className="flex">
                  <MultiSelectObjectFilter
                    filterSelect={true}
                    defaultOptions={pages.reduce((acc, page) => {
                      const isDuplicate = acc.some((option) => option.key === page._id);
                      if (!isDuplicate) {
                        acc.push({ key: page._id, value: page.name, selected: selectedPages.includes(page._id), object: page });
                      }
                      return acc;
                    }, [])}
                    searchableFields={["name"]}
                    onChange={(e) => {
                      handleSelectedItems(e, setSelectedPages);
                    }}
                  />
                </div>
              </div>
            </th>
            <th
              scope="col"
              className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              <div className="flex items-center gap-x-1">
                <p>Date</p>
                <SortOrder
                  sortDirection={sortDirection}
                  setSortDirection={(value) => {
                    setSortDirection(value);
                    setOffset(0);
                  }}
                />
              </div>
            </th>
            <th
              scope="col"
              className="rounded-r-xl relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
          </TRHeader>
        }
        colSpan="5"
        setDeleteId={setDeleteId}
        deleteId={deleteId}
        loaded={activitiesLoaded}
        dataExists={activities.length > 0}>
        {activities.map((activity) => (
          <ActivityEntry
            key={activity._id}
            activity={activity}
            deleteId={deleteId}
            setDeleteId={setDeleteId}
            dataExists={activities.length > 0}
          />
        ))}
      </Table>

      <PaginationFooter
        itemName="Activity records"
        limit={limit}
        offset={offset}
        count={activitiesMeta.count}
        onChange={handlePaginationChange}
      />
      {activities.length > 0 && (
        <ButtonExportContainer
          exportType={exportType}
          setExportType={setExportType}
          onSubmit={exportData}
        />
      )}
      <Modal
        title="Activity"
        secondaryTitle="Delete"
        defaultOptions={{
          onSuccessButtonText: "Delete",
        }}
        isOpen={!!deleteId}
        onCancel={() => setDeleteId(null)}
        onSuccess={removeDel}>
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete?</div>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    pages: Object.values(state.pages),
  };
};
export default connect(mapStateToProps, {
  getActivity,
  deleteActivityData,
  getPages,
})(ActivityList);
