import { apiUrl } from "src/config/host";
import axios from "axios";
import { toast } from "react-toastify";

const builderQueryString = (queries = []) => {
  let queryString = "";
  if (queries) {
    queryString = new URLSearchParams(queries);
    queryString = "?" + queryString.toString();
  }

  return queryString;
};

const apiRequest = async (method = "put", url = "", payload = { body: null, queries: null }, options = { useCustomUrl: false, showToastMessage: false }) => {
  let defaultPayload = { body: null, queries: null };
  payload = { ...payload, defaultPayload };

  let defaultOptions = { useCustomUrl: false, showToastMessage: false };
  options = { ...options, defaultOptions };

  try {
    let queryString = "";
    if (payload.queries != null) {
      queryString = builderQueryString(payload?.queries);
    }

    let accessToken = localStorage.getItem("access_token");

    let headers = { "Content-Type": "application/json", Accept: "application/json" };

    if (accessToken) {
      headers = {
        ...headers,
        Authorization: accessToken,
      };
    }
    const request = axios.create({
      baseURL: options.useCustomUrl ? "" : apiUrl,
      headers,
    });

    const res = await request[method.toLowerCase()](`${url}${queryString}`, payload.body);

    if (res.status === 200) {
      if (options.showToastMessage) {
        if (res.data?.message) {
          toast.success(res.data?.message);
        }
        if (res.data?.data?.message) {
          toast.success(res.data?.data?.message);
        }
      }
      return res;
    } else {
      if (options.showToastMessage) {
        if (res.data?.message) {
          toast.error(res.data?.message);
        }
        if (res.data?.data?.message) {
          toast.error(res.data?.data?.message);
        }
      }
      throw new Error(res?.message || res);
    }
  } catch (error) {
    if (options.showToastMessage) {
      toast.error(error?.response?.data);
    }
    throw new Error(error?.response?.data || error);
  }
};

export { builderQueryString, apiRequest };
