import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";

const ButtonDropdown = ({xPosition = "left", data = [], buttonIcon}) => {
  return (
    <>
      <Menu
        as="div"
        className="relative inline-block text-left">
        <div>
          <Menu.Button className="h-10 px-3 bg-white text-highlightColor border border-highlightColor flex items-center text-sm rounded focus:outline-none"> 
            <span className="sr-only">Open menu</span>
            <buttonIcon.icon className="w-6 h-6" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className={"absolute z-50 mt-2 w-56 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden " + (xPosition === "right"?"right-0":"left-0")}>
            <div className="py-1">
              {data.map((item, i) =>
                item.type === "hr" ? (
                  <div key={"hr-" + i} className="w-full my-1 h-[1px] bg-gray-100"></div>
                ) : (
                  <Menu.Item key={item.name}>
                    <button type="button" className={"group px-4 h-10 text-base text-gray-600 font-medium w-full bg-white hover:bg-highlightColor hover:text-white transition-all duration-200 flex items-center justify-start text-left"} onClick={item.onClick}>
                      {item.name}
                      {item.hasIcon &&
                      <div className="flex items-center justify-center w-6 h-6 p-1 bg-black/10 rounded ml-2 text-gray-500 group-hover:text-white transition-all duration-200">
                        <item.icon className="w-5 h-5 stroke-2"/>
                      </div>
                      }
                    </button>
                  </Menu.Item>
                )
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default ButtonDropdown;