// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import TextAccordion from "src/components/Shared/Accordions/TextAccordion";
import FrontLabeledInput from "src/components/Shared/Forms/Inputs/FrontLabeledInput";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";

const CreateDomoPageContent = ({ newPage = {}, setNewPage = () => {}, startIndex = "", pageCredentialOptions = [], advancedFeatures = "", setAdvancedFeatures = () => {} }) => {
  return (
    <>
      <div className="flex gap-x-2">
        <div className="w-full sm:w-1/2">
          <SelectMenu
            label="DOMO credentials"
            startIndex={startIndex}
            options={pageCredentialOptions || [{ key: "Select credentials", value: -1 }]}
            setOption={(option) => {
              setNewPage({ ...newPage, credential_id: option.value });
            }}
          />
        </div>
        <div className="w-full sm:w-1/2 pt-5">
          <FrontLabeledInput
            label="Embed ID"
            type="text"
            value={newPage.embed_id}
            onChange={(e) => setNewPage({ ...newPage, embed_id: e.target.value })}
          />
        </div>
      </div>
      <TextAccordion headerText="Advanced features">
        <div className="-mx-2 flex flex-wrap">
          <div className="mb-4 flex w-full px-2">
            <input
              id="card-embed"
              checked={newPage.embed_type === "EMBED_CARD" ? true : false}
              onChange={(e) => {
                setNewPage({
                  ...newPage,
                  embed_type: e.target.checked ? "EMBED_CARD" : "EMBED_PAGE",
                });
              }}
              aria-describedby="card-embed"
              name="card-embed"
              type="checkbox"
              className="h-5 w-5 rounded border-gray-300 text-highlightColor focus:ring-highlightColor"
            />
            <div className="ml-3 max-w-[400px]">
              <label
                htmlFor="card-embed"
                className="text-sm text-gray-500">
                Display page as a Domo "Card"
              </label>
              <p className="whitespace-normal text-sm text-gray-400">*Individual cards must have card embed_ids using Domo Everywhere.</p>
            </div>
          </div>
          <div className="mb-4 flex w-full px-2">
            <input
              id="ignore-global-filters"
              checked={newPage.ignore_global_filters}
              onChange={(e) => {
                setNewPage({
                  ...newPage,
                  ignore_global_filters: e.target.checked,
                });
              }}
              aria-describedby="ignore-global-filters"
              name="ignore-global-filters"
              type="checkbox"
              className="h-5 w-5 rounded border-gray-300 text-highlightColor focus:ring-highlightColor"
            />
            <div className="ml-3 max-w-[400px]">
              <label
                htmlFor="ignore-global-filters"
                className="text-sm text-gray-500">
                Ignore global filters
              </label>
              <p className="whitespace-normal text-sm text-gray-400">If enabled, both global user filters and global workspace filters will be ignored when viewing this page.</p>
            </div>
          </div>
        </div>
      </TextAccordion>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {})(CreateDomoPageContent);
