import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { userPermissionCheckboxManage, userPermissionList, usersPermissionManage } from "src/actions/workspace";
import WorkspaceManageUsersModal from "src/components/Workspaces/Workspace/WorkspaceManageUsersModal";
import { classNames } from "src/helpers/classNames";
import WorkspacePermissionTabData from "src/components/Workspaces/Workspace/Permissions/WorkspacePermissionTabData";
import useFetch from "src/hooks/useFetch";
import { urlBuilder } from "src/helpers/urlBuilder";
import { apiRequest } from "src/async/apiUtils";

const WorkspacePermissions = ({ workspaceId, ...props }) => {
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedTab, setSelectedTab] = useState("user");
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [workspaceUsers, setWorkspaceUsers] = useState([]);
  const tabs = [
    { name: "Users", code: "user" },
    { name: "Groups", code: "group" },
    { name: "Pages", code: "page" },
    { name: "Permissions", code: "permission" },
    { name: "Layout & Styles", code: "layout" },
    { name: "Integrations", code: "integration" },
  ];

  const handleAddUserModal = (value) => {
    setIsAddUserModalOpen(value);
    resetUserIds();
  };

  const resetUserIds = () => {
    let userIds = editorUsers.map((user) => user?.user_id?._id);
    setSelectedUser(userIds);
  };

  const {
    response: { data: editorUsers },
    refreshData: refreshEditorUsers,
  } = useFetch(urlBuilder(`/workspaces/:workspace_id/users/permissions/list`, { workspace_id: workspaceId }));

  const getUsers = async () => {
    refreshEditorUsers();
  };

  useEffect(() => {
    resetUserIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorUsers]);

  useEffect(() => {
    loadWorkspaceUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ workspaceId ]);

  const loadWorkspaceUserData = async () => {
    try {
      const res = await apiRequest("get", `/workspaces/${workspaceId}/users`, {});

      if (res.data) {
        setWorkspaceUsers(res.data.data);
      }
    } catch (error) {
      // console.dir("ERROR:", error);
    }
  };

  const userAdd = async (selectedUser) => {
    try {
      const data = await props.usersPermissionManage({ user_id: selectedUser, workspace_id: workspaceId });
      getUsers();
      setIsAddUserModalOpen(false);
      setSelectedUser([]);
      toast.success(data.message);
    } catch (error) {
      setIsAddUserModalOpen(false);
      setSelectedUser([]);
      toast.success(error.message);
    }
  };

  const handleSinglePermissionChange = async (e, currentUser) => {
    try {
      let permissions = [];
      let resetUserList = [];
      for (let i = 0; i < editorUsers?.length; i++) {
        const user = editorUsers[i];
        if (currentUser._id === user._id) {
          permissions.push({ _id: user._id, [e.target.name]: e.target.checked });
          resetUserList.push({ ...user, [e.target.name]: e.target.checked });
        } else {
          resetUserList.push(user);
        }
      }

      await props.userPermissionCheckboxManage({ permissions: permissions, workspace_id: workspaceId });
      refreshEditorUsers();
    } catch (error) {
      console.dir(error);
    }
  };

  const handleMultiplePermissionChanges = async (e) => {
    try {
      let permissions = [];
      let resetUserList = [];
      for (let i = 0; i < editorUsers?.length; i++) {
        const user = editorUsers[i];
        permissions.push({ _id: user._id, [e.target.name]: e.target.checked });
        resetUserList.push({ ...user, [e.target.name]: e.target.checked });
      }

      await props.userPermissionCheckboxManage({ permissions: permissions, workspace_id: workspaceId });
      refreshEditorUsers();
    } catch (error) {}
  };

  return (
    <>
      <div className="relative w-full rounded-md space-y-4 h-auto">
        <>
          <div className="relative w-full max-w-5xl shadow rounded-md bg-white border border-slate-100">
            <div className="relative mb-5 bg-slate-100">
              <nav
                className="flex"
                aria-label="Tabs">
                {tabs.map((tab) => (
                  <div
                    key={tab.code}
                    onClick={() => setSelectedTab(tab.code)}
                    className={classNames(tab.code === selectedTab ? "!bg-white text-slate-800 " : "text-slate-400 hover:bg-slate-50 hover:text-slate-600", "py-4 px-6 bg-transparent w-full flex justify-center items-center rounded-t-md cursor-pointer")}
                    aria-current={tab.code === selectedTab ? "page" : undefined}>
                    <span className="font-semibold">{tab.name}</span>
                  </div>
                ))}
              </nav>
            </div>
            <div className="mt-4">
              <WorkspacePermissionTabData
                workspaceId={workspaceId}
                isAddUserModalOpen={isAddUserModalOpen}
                setIsAddUserModalOpen={setIsAddUserModalOpen}
                userList={editorUsers}
                disabledColumns={tabs.filter((tab) => tab.code === selectedTab)[0].disabledSections}
                selectedTab={selectedTab}
                handleSinglePermissionChange={handleSinglePermissionChange}
                handleMultiplePermissionChanges={handleMultiplePermissionChanges}
              />
            </div>
          </div>
        </>
      </div>
      <WorkspaceManageUsersModal
        title="Workspace Editors"
        secondaryTitle="Manage"
        isPermissionPage={true}
        isOpen={isAddUserModalOpen}
        setIsOpen={handleAddUserModal}
        pSelectedUser={selectedUser}
        defaultSelectedUsers={selectedUser}
        setSelectedUser={setSelectedUser}
        onSuccess={userAdd}
        onCancel={() => setIsAddUserModalOpen(false)}
        users={editorUsers}
        workspaceUsers={workspaceUsers}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};
export default connect(mapStateToProps, { userPermissionList, usersPermissionManage, userPermissionCheckboxManage })(WorkspacePermissions);
