import { useEffect, useState } from "react";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Select from "src/components/Shared/Forms/Selects/Select";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";

const WorkspaceIntegrationFiltersItem = ({ user, workspaceId, state, setState = () => { }, removeFilter, operators, trustedAttributeArr, valueType, }) => {
  const [valueTypeOption, setValueTypeOption] = useState(valueType);
  const [operatorId, setOperatorId] = useState({ _id: -1, name: "" });
  const [valueTypeId, setValueTypeId] = useState({ _id: 'value', name: "Text Value" });
  const [trustedAttributeId, setTrustedAttributeId] = useState({ _id: -1, name: "" });

  useEffect(() => {
    const index = valueType.findIndex((item) => item._id === state?.value_type);
    if (index !== -1) {
      setValueTypeId(valueType[index]);
      setValueTypeOption(valueType.filter((v) => v._id !== state?.value_type))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.value_type]);

  useEffect(() => {
    const index = operators.findIndex((item) => item._id === state?.operator_id);
    if (index !== -1) {
      setOperatorId(operators[index]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.operator_id, operators?.length]);

  useEffect(() => {
    const index = trustedAttributeArr.findIndex((item) => item._id === state?.trusted_attribute);
    if (index !== -1) {
      setTrustedAttributeId(trustedAttributeArr[index]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.trusted_attribute]);

  return (
    <>
      <div className="relative w-full items-end flex flex-col sm:flex-row gap-x-3 max-w-4xl">
        {authorizeUserComponentAccess(user, workspaceId, "integration", ["update"]) ? (
          <>
            <div className="grow">
              <Input
                name="column-name"
                label="Column name"
                value={state?.column_name || ""}
                onChange={(e) => {
                  setState({ ...state, column_name: e.target.value });
                }}
              />
            </div>
            <div className="w-[110px]">
              <Select
                label="Operator"
                options={operators}
                selected={operatorId}
                setSelected={(e) => {
                  setState({ ...state, operator_id: e._id });
                }}
              />
            </div>
            <div className="w-1/2">
              <Select
                options={valueTypeOption}
                selected={valueTypeId}
                selectType={'label'}
                setSelected={(e) => {
                  setState({ ...state, value_type: e._id, column_value: '', trusted_attribute: '' });
                  setTrustedAttributeId({ _id: -1, name: "" })
                }}
              />
              {state?.value_type === 'attribute' ? (
                <Select
                  options={trustedAttributeArr}
                  selected={trustedAttributeId}
                  setSelected={(e) => {
                    setState({ ...state, trusted_attribute: e._id });
                  }}
                />
              ) : (
                <Input
                  name="column-value"
                  value={state?.column_value || ""}
                  onChange={(e) => {
                    setState({ ...state, column_value: e.target.value });
                  }}
                />
              )}
            </div>
          </>
        ) : (
          <>
            <div className="grow">
              <Input
                name="column-name"
                label="Column name"
                value={state?.column_name || ""}
                disabled={true}
                onChange={(e) => { }}
              />
            </div>
            <div className="w-[110px]">
              <Select
                label="Operator"
                options={operators}
                selected={operatorId}
                disabled={true}
                setSelected={(e) => { }}
              />
            </div>
            <div className="w-1/2">
              <Select
                options={valueTypeOption}
                selected={valueTypeId}
                selectType={'label'}
                setSelected={(e) => { }}
              />
              {state?.value_type === 'attribute' ? (
                <Select
                  options={trustedAttributeArr}
                  selected={trustedAttributeId}
                  setSelected={(e) => { }}
                />
              ) : (
                <Input
                  name="column-value"
                  value={state?.column_value || ""}
                  onChange={(e) => { }}
                />
              )}
            </div>
          </>
        )}
        {authorizeUserComponentAccess(user, workspaceId, "integration", ["delete"]) &&
          <div className="h-10 w-10 absolute md:relative md:bottom-auto md:right-auto ml-4 bottom-1 right-3">
            <button type="button" className="p-1 hover:bg-gray-50 transition-all duration-300 bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" onClick={() => removeFilter(state._id)}>
              <span className="sr-only">Delete</span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="h-6 w-6">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
              </svg>
            </button>
          </div>
        }
      </div>
    </>
  );
};

export default WorkspaceIntegrationFiltersItem;
