import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { fetchData } from "src/async/fetch";
import FrontLabeledInput from "src/components/Shared/Forms/Inputs/FrontLabeledInput";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Modal from "src/components/Shared/Modal";
import { apiUrl } from "src/config/host";

const WorkspaceAddModal = ({ isOpen, workspaceType, onCancel = () => {}, onSuccess = () => {} }) => {
  const [workspaceTypeName, setWorkspaceTypeName] = useState("");
  const [name, setName] = useState("");
  const [subscriberInstanceURL, setSubscriberInstanceURL] = useState("");
  const [keyAttributeValue, setKeyAttributeValue] = useState("");
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");

  const createWorkspace = async () => {
    try {
      const res = await fetchData("POST", `${apiUrl}/workspaces/add`, {
        name,
        subscriber_instance_url: subscriberInstanceURL,
        key_attribute_value: keyAttributeValue,
        client_id: clientId,
        client_secret: clientSecret,
        workspace_type: workspaceType,
      });
      const data = await res.json();
      if (data.status === 200) {
        onSuccess();
        setName("");
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (workspaceType) {
      setWorkspaceTypeName(workspaceType.charAt(0).toUpperCase() + workspaceType.slice(1).toLowerCase().replace("_", " "));
      setName("");
      setSubscriberInstanceURL("");
      setKeyAttributeValue("");
      setClientId("");
      setClientSecret("");
    }
  }, [workspaceType]);

  return (
    <Modal
      title="Workspace"
      secondaryTitle={`Create ${workspaceTypeName}`}
      isOpen={isOpen}
      onCancel={onCancel}
      onSuccess={createWorkspace}
      defaultOptions={{
        onSuccessButtonText: "Save",
      }}>
      <div className="relative w-full space-y-4">
        <div className="relative w-full mb-4">
          <Input
            name="name"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        {workspaceType === "JWT_FULL_EMBED" && (
          <>
            <div className="relative w-full mb-4">
              <Input
                name="subscriber-url"
                label="Subscriber Instance URL"
                value={subscriberInstanceURL}
                onChange={(e) => setSubscriberInstanceURL(e.target.value)}
              />
            </div>
            <div className="relative w-full mb-4">
              <Input
                name="attribute"
                label="Key Attribute Value"
                value={keyAttributeValue}
                onChange={(e) => setKeyAttributeValue(e.target.value)}
              />
            </div>
            <div className="relative w-full mb-4">
              <label className="flex justify-between text-sm font-medium text-gray-700 mb-1">Authentication</label>
              <div className="grid grid-cols-2 gap-4">
                <FrontLabeledInput
                  label="Client ID"
                  type="password"
                  value={clientId}
                  onChange={(e) => setClientId(e.target.value)}
                  disabled={false}
                />
                <FrontLabeledInput
                  label="Client Secret"
                  type="password"
                  value={clientSecret}
                  onChange={(e) => setClientSecret(e.target.value)}
                  disabled={false}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default WorkspaceAddModal;
