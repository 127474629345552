import { WindowIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { addGroup, editGroupData } from "src/actions/user";
import AddPermissionForm from "src/components/PageForm/AddPermissionForm";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import MultiSelectObject from "src/components/Shared/Forms/Selects/MultiSelectObject";
import Select from "src/components/Shared/Forms/Selects/Select";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";
import Modal from "src/components/Shared/Modal";
import useFetch from "src/hooks/useFetch";
import UserProfile from "../Users/UserProfile";
import WorkspaceTag from "../Workspaces/WorkspaceTag";

const permissionJson = {
  _id: null,
  page_id: null,
  filters: [
    {
      column_name: "",
      operator_id: null,
      value_type: "value",
      column_value: "",
      trusted_attribute: "",
    },
  ],
};

const GroupModalEdit = ({ isOpen = false, closeModal = () => {}, submitText, cancelText, title = "", secondaryTitle = "", defaultWorkspaceId = "", children, onSubmit = () => {}, removeSubmit = false, removeClose = false, setRefresh = () => {}, ...props }) => {
  const [name, setName] = useState("");
  const [tag, setTag] = useState("");
  const [tagList, setTagList] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState({ _id: -1, name: "None selected" });
  const [selectedUser, setSelectedUser] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [permissionState, setPermissionState] = useState([]);
  const [group, setGroup] = useState({});

  const {
    response: { data: users },
    status: { done: usersLoaded },
  } = useFetch("/users/list", { query: { active_status: true } });

  const {
    response: { data: workspaces },
    status: { done: workspacesLoaded },
  } = useFetch("/workspaces/list", { method: "post", data: { workspace_type: "IFRAME_EMBED" } });

  const {
    response: { data: pages },
    status: { done: pagesLoaded },
  } = useFetch("/pages/list");

  const {
    response: { data: activeSSO },
  } = useFetch("/sso/is-active");

  const handleSelectedUsers = (usersArray) => {
    let updatedSelectedUsers = usersArray.reduce((users, user) => (user.selected ? [...users, user.key] : users), []);
    setSelectedUser(updatedSelectedUsers);
  };

  const handleClearValues = () => {
    setGroup({});
    setName("");
    setTag("");
    setTagList([]);
    setSelectedWorkspace({ _id: -1, name: "None selected" });
    setPageList([]);
    setSelectedUser([]);
    setPermissionState([]);
    props.setIsOpen(false);
    props.setAddGroupStatus(false);
    props.setEditGroupStatus(false);
    props.setEditId(null);
  };

  useEffect(() => {
    if (defaultWorkspaceId && workspacesLoaded) {
      let defaultWorkspace = workspaces.find((workspace) => workspace._id === defaultWorkspaceId);
      setSelectedWorkspace({ _id: defaultWorkspace._id, name: defaultWorkspace.name });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces, workspacesLoaded, defaultWorkspaceId]);

  useEffect(() => {
    if (selectedWorkspace && Array.isArray(pages)) {
      setPageList(pages.filter((page) => page.workspace_id?._id === selectedWorkspace._id && page.page_type !== "LANDING_PAGE"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages?.length, pagesLoaded, selectedWorkspace]);

  useEffect(() => {
    if (props?.editId && props.groups.length) {
      const findGroup = props.groups.find((group) => group._id === props?.editId);
      setGroup(findGroup);
      setName(findGroup?.name);
      setTagList(findGroup?.tags || []);
      setSelectedWorkspace(findGroup?.workspace_id);
      setPageList(pages.filter((pg) => pg.workspace_id?._id === findGroup?.workspace_id?._id && pg.page_type !== "LANDING_PAGE"));
      setSelectedUser(findGroup?.user_id);
      setPermissionState(findGroup.permissions.length > 0 ? findGroup.permissions : [permissionJson]);
    } else {
      handleClearValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.editId, props?.groups]);

  const updateWorkspace = async (updatedWorkspace) => {
    setSelectedWorkspace(updatedWorkspace);
    setPageList(pages.filter((pg) => pg.workspace_id?._id === updatedWorkspace._id && pg.page_type !== "LANDING_PAGE"));
    setPermissionState([]);
  };

  const createGroup = async () => {
    try {
      const data = await props.addGroup({
        name: name,
        workspace_id: selectedWorkspace._id,
        user_id: selectedUser,
        permissions: permissionState,
        tags: tagList,
        auth_token: props.workspaceDetails?.auth_token,
      });
      handleClearValues();

      setRefresh();
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const editGroup = async () => {
    try {
      const data = await props.editGroupData({
        id: group?._id,
        name: name,
        workspace_id: selectedWorkspace._id,
        user_id: selectedUser,
        permissions: permissionState,
        tags: tagList,
        auth_token: props.workspaceDetails?.auth_token,
      });
      handleClearValues();

      setRefresh();
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const groupModalSubmit = async () => {
    if (props.addGroupStatus) {
      await createGroup();
    } else if (props.editGroupStatus) {
      await editGroup();
    }
    return;
  };

  const addNewPermission = (e) => {
    e.preventDefault();
    setPermissionState([...permissionState, permissionJson]);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(permissionState, result.source.index, result.destination.index);
    setPermissionState([...items]);
  };

  const handleTag = async (e) => {
    if (e.code === "Enter" || e.code === "Comma") {
      e.preventDefault();
      if (tagList.findIndex((tg) => tg.toLowerCase() === tag.toLowerCase()) === -1) {
        const tags = [...tagList, tag];
        setTagList(tags);
        setTag("");
      }
    }
  };

  const removeTag = async (index) => {
    tagList.splice(index, 1);
    setTagList([...tagList]);
  };

  return (
    <Modal
      title={title}
      secondaryTitle={secondaryTitle}
      isOpen={isOpen}
      S
      onCancel={() => {
        props.setIsOpen(!isOpen);
        props.setEditId(null);
      }}
      onSuccess={() => {
        onSubmit();
        groupModalSubmit();
      }}
      defaultOptions={{
        onSuccessButtonText: "Save",
      }}>
      <div className="relative w-full space-y-4">
        <div className="relative w-full mb-4">
          <Input
            name="name"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        {activeSSO?.isSSOActive && (
          <div className="mb-4 space-y-4">
            <div className="w-full">
              <Input
                type="text"
                autoComplete="off"
                name="tag-name"
                label="Tags"
                value={tag}
                onChange={(e) => setTag(e.target.value)}
                onKeyDown={handleTag}
              />
            </div>
            {tagList.length > 0 && (
              <div className="flex flex-wrap items-center gap-2">
                {tagList.map((tag, index) => {
                  return (
                    <WorkspaceTag
                      index={index}
                      tag={tag}
                      onDelete={removeTag}
                    />
                  );
                })}
              </div>
            )}
          </div>
        )}

        {!defaultWorkspaceId && (
          <div className="relative w-full mb-4">
            <Select
              label={"Workspace"}
              options={workspacesLoaded ? workspaces : []}
              selected={selectedWorkspace}
              setSelected={(e) => updateWorkspace(e)}
            />
          </div>
        )}

        <div className="relative w-full mb-4">
          <MultiSelectObject
            defaultOptions={
              usersLoaded &&
              users.map((user) => {
                return { key: user._id, value: user.name, iconValue: { icon: UserProfile, params: { user, className: "h-6 w-6 rounded-full overflow-hidden", size: "sm" } }, secondaryValue: user.email, selected: selectedUser.includes(user._id), object: user };
              })
            }
            searchableFields={["name", "email"]}
            title="Assign users"
            onChange={handleSelectedUsers}
          />
        </div>

        {selectedWorkspace.workspace_type === "IFRAME_EMBED" && (
          <div className="relative w-full">
            <div className="flex items-center justify-between mb-4">
              <div className="ml-auto">
                <Button
                  disabled={pageList.length === 0}
                  version="secondary"
                  onClick={addNewPermission}>
                  Add Page
                  <IconsWithPlus
                    strokeColor={pageList.length === 0 ? "stroke-gray-300" : "stroke-highlightColor"}
                    item={{ icon: WindowIcon }}
                  />
                </Button>
              </div>
            </div>

            <div className="space-y-8 mb-8 max-h-[160px] overflow-y-scroll">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}>
                      {permissionState.map((count, i) => {
                        return (
                          <Draggable
                            key={i}
                            draggableId={`${i}`}
                            index={i}>
                            {(provided, snapshot) => (
                              <AddPermissionForm
                                isGroup={true}
                                innerRef={provided.innerRef}
                                draggableProps={provided.draggableProps}
                                dragHandleProps={provided.dragHandleProps}
                                key={i}
                                index={i}
                                pages={pageList}
                                state={permissionState}
                                setState={setPermissionState}
                              />
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};
export default connect(mapStateToProps, { addGroup, editGroupData })(GroupModalEdit);
