import { ClipboardDocumentCheckIcon, Cog8ToothIcon, PaintBrushIcon, RectangleStackIcon, TrashIcon, UsersIcon, WindowIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { filterPageCredentialOptions, getPageCredentialOptions, managePageIntegrationOptions } from "src/actions/page";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import Section from "src/components/Shared/Containers/Section";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import { H3 } from "src/components/Shared/Text/Headers";
import WorkspaceIntegrationItem from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationItem";
import WorkspaceIntegrationCredentials from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegrationCredentials";
import { randomHashString } from "src/helpers/randomHashString";
import useFetch from "src/hooks/useFetch";
import Modal from "../Shared/Modal";

const GlobalSettings = ({ getPageCredentialOptions = () => { }, managePageIntegrationOptions = () => { }, filterPageCredentialOptions = () => { }, credentials = [] }) => {
  const {
    response: { data: site },
    refreshData: refreshSite,
  } = useFetch("/site-settings/unprotected");

  const {
    response: { data: integrations },
    status: { done: integrationsLoaded },
    refreshData: refreshIntegrations
  } = useFetch(`/integrations/global`);

  const {
    response: { data: workspacesWithIntegrations },
    status: { done: workspacesWithIntegrationsLoaded },
  } = useFetch(`/workspaces/integrations`);

  // const typeOption = [
  //   { key: "All Types", value: "" },
  //   { key: "Dashboard Embed", value: "IFRAME_EMBED" },
  //   { key: "Edit Embed", value: "JWT_FULL_EMBED" },
  // ];
  const [list, setList] = useState([]);
  const [credLoading, setCredLoading] = useState(true);
  const [newEmbeded, setNewEmbeded] = useState({});
  const [editEmbeded, setEditEmbeded] = useState(false);
  const [deleteFullAppEmbedId, setDeleteFullAppEmbedId] = useState(null);

  const deleteFullAppEmbedCredential = async () => {
    try {
      const res = await apiRequest("DELETE", `/integrations/global/${deleteFullAppEmbedId}`);
      const data = res.data;
      if (data.status === 200) {
        setDeleteFullAppEmbedId(null);
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const loadPageCreds = async () => {
    setCredLoading(true);
    const ac = new AbortController();
    try {
      const data = await getPageCredentialOptions({}, ac.signal);
      setList(data);
      setCredLoading(false);
    } catch (error) {
      setCredLoading(false);
    }
    return () => ac.abort();
  };

  useEffect(() => {
    loadPageCreds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [siteDomain, setSiteDomain] = useState("");
  const [loginPageTitle, setLoginPageTitle] = useState("");
  const [loginPageSubtitle, setLoginPageSubtitle] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    setSiteDomain(site?.site_domain);
    setLoginPageTitle(site?.login_page_title);
    setLoginPageSubtitle(site?.login_page_subtitle);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site?._id]);

  const onSubmit = async () => {
    try {
      setIsDisabled(true)
      let body = {
        site_domain: siteDomain,
        login_page_title: loginPageTitle,
        login_page_subtitle: loginPageSubtitle,
      };
      await apiRequest("put", "/site-settings", { body });

      refreshSite();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const createNewEditEmbed = async () => {
    const ac = new AbortController();
    try {
      const { message } = await managePageIntegrationOptions(newEmbeded);
      loadPageCreds();
      refreshIntegrations();
      setEditEmbeded(false);
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
    return () => ac.abort();
  };

  // const removeCredential = async (payload) => {
  //   try {
  //     await apiRequest("DELETE", `credentials/${payload._id}`);
  //     // refreshCredentials();
  //   } catch (error) {
  //     toast.error(error.message);
  //   }
  // };

  return (
    <>
      <div className="flex flex-col gap-y-4">
        <Section>
          <H3 caption="Setup general website items for the best user experience.">General Settings</H3>
          <div className="flex flex-wrap w-full gap-y-6 gap-x-4">
            <div className="w-full px-2">
              <Input
                name="site-domain"
                label="User Access URL"
                value={siteDomain}
                onChange={(e) => {setIsDisabled(false);setSiteDomain(e.target.value)}}
              />
            </div>
            <div className="w-full px-2">
              <Input
                name="login-page-title"
                label="Login Page Title"
                value={loginPageTitle}
                onChange={(e) => {setIsDisabled(false);setLoginPageTitle(e.target.value)}}
              />
            </div>
            <div className="w-full px-2">
              <Input
                name="login-page-subtitle"
                label="Login Page Subtitle"
                value={loginPageSubtitle}
                onChange={(e) => {setIsDisabled(false);setLoginPageSubtitle(e.target.value)}}
              />
            </div>
          </div>
          <div className="flex justify-end gap-x-4 items-end mt-4 px-2">
            <div className="h-10 flex gap-x-2">
              <Button
                version="gray"
                disabled={isDisabled}
                onClick={() => {
                  setSiteDomain(site.site_domain)
                  setLoginPageTitle(site.login_page_title)
                  setLoginPageSubtitle(site.login_page_subtitle)
                  setIsDisabled(true)
                }}>
                Undo
              </Button>
              <Button
                disabled={isDisabled}
                onClick={onSubmit}>
                Update
              </Button>
            </div>
          </div>
        </Section>
        <Section className="gap-y-6">
          <H3 caption="Below list credentials can be used by admins in all workspaces.">Global Credentials</H3>
          {integrationsLoaded ? (
            integrations?.length > 0 &&
            integrations.map((integration) => {
              return (
                <WorkspaceIntegrationItem
                  key={integration._id}
                  integration={integration}
                  workspaceId={null}
                  is_global={true}
                />
              );
            })
          ) : (
            <Preloader />
          )}
          <hr />
          <div>
            <H3 caption="Credential access assigned to a particular workspace.">Workspace Credentials</H3>
            <div className="grid gap-y-4">
              {workspacesWithIntegrationsLoaded ? (
                workspacesWithIntegrations?.length > 0 &&
                workspacesWithIntegrations.map((workspaceWithIntegrations) => {
                  return (
                    <Fragment key={workspaceWithIntegrations._id}>
                      {workspaceWithIntegrations?.integrations?.length > 0 &&
                        workspaceWithIntegrations.integrations.map((integration) => {
                          return (
                            <WorkspaceIntegrationCredentials
                              key={integration._id}
                              integration={integration}
                              workspace={workspaceWithIntegrations}
                              viewOnly={true}
                            />
                          );
                        })}
                    </Fragment>
                  );
                })
              ) : (
                <Preloader />
              )}
            </div>
          </div>
        </Section>
        <Section className="gap-y-6">
          <H3 caption="Embed your analytics application directly into the workspace.">Edit Embed</H3>
          {list.length && !credLoading ? (
            <>
              {list.map((integration) => {
                return (
                  <>
                    {integration?.jwt_secret_count > 0 && integration?.embed_url && integration?.key_attribute ?
                      (
                        <div key={integration?._id} className="flex items-center justify-between mb-1 mt-5">
                          <div className="flex gap-x-14 w-2/3">
                            <div className="border rounded overflow-hidden border-gray-200 py-2 px-4 text-gray-800 flex gap-x-4 w-[170px] justify-between items-center">
                              <div className="w-10 h-10 overflow-hidden">
                                <img
                                  className="w-full h-full object-cover"
                                  src="https://www.domo.com/assets/images/logo@2x.png"
                                  alt=""
                                />
                              </div>
                              Edit Embed
                            </div>
                            <div className="w-4/5 flex gap-x-4 pb-3">
                              <div className="sm:w-2/3 w-full">
                                <Input
                                  type="text"
                                  name="embeded-url"
                                  autoComplete="off"
                                  disabled
                                  label="Identity broker URL"
                                  value={integration.embed_url || ""}
                                />
                              </div>
                              <div className="sm:w-1/3 w-full">
                                <Input
                                  type="text"
                                  name="attribute"
                                  disabled
                                  autoComplete="off"
                                  label="Key attribute"
                                  value={integration.key_attribute || ""}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center gap-x-3">
                            <Button
                              version="secondary"
                              onClick={() => {
                                setNewEmbeded({
                                  jwt_secret_count: integration.jwt_secret_count,
                                  embed_url: integration.embed_url,
                                  key_attribute: integration.key_attribute,
                                  _id: integration._id,
                                });
                                setEditEmbeded(true);
                              }}>
                              Edit
                            </Button>
                            <button
                              type="button"
                              onClick={() => setDeleteFullAppEmbedId(integration._id)}
                              className="w-8 h-8 flex items-center justify-center border-gray-200 rounded-md text-gray-500 hover:text-gray-600 outline-none ring-0 transition-all duration-200 hover:bg-gray-50">
                              <TrashIcon className="w-5 h-5" />
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div key={integration?._id} className="mt-4 m-0 text-md font-medium text-gray-400 ml-3 flex w-full justify-between">
                          <p className="px-3.5 py-3 rounded-md bg-gray-50">No credentials created</p>{" "}
                          <Button
                            version="secondary"
                            onClick={() => {
                              setNewEmbeded({
                                jwt_secret_count: 0,
                                embed_url: "",
                                key_attribute: "keyAttribute",
                                _id: list[0]?._id,
                              });
                              setEditEmbeded(true);
                            }}>
                            Add
                          </Button>
                        </div>
                      )
                    }
                  </>
                );
              })}
              <Modal
                title="Full App Embed"
                secondaryTitle="Delete"
                defaultOptions={{
                  onSuccessButtonText: "Delete",
                }}
                isOpen={!!deleteFullAppEmbedId}
                onCancel={() => setDeleteFullAppEmbedId(null)}
                onSuccess={deleteFullAppEmbedCredential}>
                <div className="grid gap-y-2 whitespace-nowrap text-sm text-gray-500">
                  <p>
                    Are you sure you want to remove this <span className="italic">Full App Embed</span> integration?
                  </p>
                  <ul className="pl-6 grid gap-y-5 list-disc mb-10">
                    <li>
                      Related
                      <div className="mx-1 py-1 px-1.5 rounded-md inline-flex items-center gap-x-2 bg-gray-100">
                        <UsersIcon className="h-4 w-4" />
                        user
                      </div>
                      accounts will <span className="font-bold">NOT</span> be deleted
                    </li>
                    <li>
                      Domo instance
                      <div className="mx-1 py-1 px-1.5 rounded-md inline-flex items-center gap-x-2 bg-gray-100">
                        <WindowIcon className="h-4 w-4" />
                        pages
                      </div>
                      and
                      <div className="mx-1 py-1 px-1.5 rounded-md inline-flex items-center gap-x-2 bg-gray-100">
                        <ClipboardDocumentCheckIcon className="h-4 w-4" />
                        groups
                      </div>
                      remotely created will <span className="font-bold">NOT</span> be deleted
                    </li>
                    <li>
                      Related
                      <div className="mx-1 py-1 px-1.5 rounded-md inline-flex items-center gap-x-2 bg-gray-100">
                        <RectangleStackIcon className="h-4 w-4" />
                        workspaces
                      </div>
                      and their
                      <div className="mx-1 py-1 px-1.5 rounded-md inline-flex items-center gap-x-2 bg-gray-100">
                        <Cog8ToothIcon className="h-4 w-4" />
                        settings
                      </div>
                      and
                      <div className="mx-1 py-1 px-1.5 rounded-md inline-flex items-center gap-x-2 bg-gray-100">
                        <PaintBrushIcon className="h-4 w-4" />
                        styles
                      </div>
                      will be <span className="font-bold">DELETED</span>
                    </li>
                  </ul>
                </div>
              </Modal>
            </>
          ) : credLoading && (
            <Preloader />
          )}
        </Section>
      </div>

      {/* Edit */}
      <Modal
        title="Domo Edit Embed Integration"
        isOpen={editEmbeded}
        onCancel={() => setEditEmbeded(false)}
        onSuccess={createNewEditEmbed}>
        <div className="relative">
          <div className="mb-4">
            <Input
              type="password"
              name="jwt-secret"
              autoComplete="new-password"
              label="JWT secret"
              placeholder={randomHashString(newEmbeded.jwt_secret_count)}
              value={newEmbeded.jwt_secret || ""}
              onChange={(e) => setNewEmbeded({ ...newEmbeded, jwt_secret: e.target.value })}
            />
          </div>
          <div className="mb-4">
            <Input
              type="text"
              name="embeded-url"
              autoComplete="off"
              label="Embed URL for identity broker"
              value={newEmbeded.embed_url || ""}
              onChange={(e) => setNewEmbeded({ ...newEmbeded, embed_url: e.target.value })}
            />
          </div>
          <div className="mb-4">
            <Input
              type="text"
              name="attribute"
              autoComplete="off"
              label="Key attribute field name"
              value={newEmbeded.key_attribute || ""}
              onChange={(e) => setNewEmbeded({ ...newEmbeded, key_attribute: e.target.value })}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    credentials: state.pageCredentialOptions,
  };
};

export default connect(mapStateToProps, { getPageCredentialOptions, managePageIntegrationOptions, filterPageCredentialOptions })(GlobalSettings);
