import GroupList from "src/components/Groups/GroupList";
import { H1 } from "src/components/Shared/Text/Headers";

const Groups = () => {
  return (
    <>
      <H1>Groups</H1>
      <GroupList />
    </>
  );
};

export default Groups;
