import { WindowIcon, RectangleStackIcon, ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getOperators } from "src/actions/operator";
import { getUserWorkspaceData } from "src/actions/workspace";
import Accordion from "src/components/Shared/Accordions/Accordion";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import WorkspaceGroupListOldVersion from "src/components/Workspaces/Workspace/Groups/WorkspaceGroupListOldVersion";
import WorkspaceIndividualPermission from "src/components/Workspaces/Workspace/Groups/WorkspaceIndividualPermission";
import { baseUrl, noFavicon } from "src/config/host";
import Tabs from "src/components/Shared/Tabs/Tabs";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import { manageUserRole } from "src/actions/user";
import { toast } from "react-toastify";
import Modal from "src/components/Shared/Modal";
import { H3 } from "src/components/Shared/Text/Headers";
import Button from "src/components/Shared/Buttons/Button";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";
import { apiRequest } from "src/async/apiUtils";
import MultiSelectObject from "src/components/Shared/Forms/Selects/MultiSelectObject";

const UserWorkspaceList = ({ userDetails = {}, ...props }) => {
  const { id } = useParams();
  const [loaded, setLoaded] = useState(false);
  const [workspaces, setWorkspaces] = useState([]);
  const [allWorkspaces, setAllWorkspaces] = useState([]);
  const [workspaceModalOpen, setWorkspaceModalOpen] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState([]);
  const [workspaceUsers, setWorkspaceUsers] = useState([]);
  const roles = [
    { key: "Participant", value: "participant" },
    { key: "Editor", value: "editor" },
    { key: "Privileged", value: "privileged" },
    { key: "Admin", value: "admin" },
  ];

  const loadData = async () => {
    const getWorkspaces = await props.getUserWorkspaceData({ user_id: id });
    setWorkspaces(getWorkspaces);
    setLoaded(true);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loadWorkspaces = async () => {
      const res = await apiRequest("post", "/workspaces/list", {
        body: {
          includes: ["groups"],
        },
      });
      const data = res.data;
      if (data.status === 200) {
        setAllWorkspaces(data.data);
      }
    };
    loadWorkspaces();
  }, []);

  useEffect(() => {
    setSelectedWorkspace(workspaces.map((wrk) => wrk._id));
  }, [workspaces]);

  const manageUserRole = async (e, workspace_id, user) => {
    try {
      const data = await props.manageUserRole({
        id: user.id,
        type: e.value,
        auth_token: props.workspaceDetails?.auth_token,
        workspace_id,
      });
      toast.success(data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSelectedWorkspace = (workspacesArray) => {
    let updatedSelectedWorkspaces = workspacesArray.reduce((workspaces, workspace) => (workspace.selected ? [...workspaces, workspace.key] : workspaces), []);
    setSelectedWorkspace(updatedSelectedWorkspaces);
  };

  const userAssignToWorkspace = async () => {
    try {
      const res = await apiRequest("post", `/users/${id}/workspace-assign`, {
        body: {
          workspaces: selectedWorkspace,
        },
      });
      const data = res.data;
      if (data.status === 200) {
        setWorkspaceModalOpen(false);
        loadData();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    const loadWorkspaceUserData = async () => {
      try {
        const res = await apiRequest("get", `/users/list`, {});
        if (res.data) {
          setWorkspaceUsers(res.data.data);
        }
      } catch (error) {
        // console.dir("ERROR:", error);
      }
    };
    loadWorkspaceUserData();
  }, []);

  return (
    <>
      <div className="flex items-center">
        <H3 caption="Organize your pages into packages and assign users to have access.">Workspace Groups</H3>
        <div className="flex justify-end h-10">
          <Button
            version="secondary"
            onClick={() => {
              setWorkspaceModalOpen(true);
            }}>
            <IconsWithPlus
              strokeColor={"stroke-highlightColor"}
              item={{ icon: RectangleStackIcon }}
            />
          </Button>
        </div>
      </div>
      {loaded ? (
        workspaces.length > 0 && (
          <div className="relative grid gap-y-4 bg-transparent rounded-xl">
            {workspaces.map((workspace) => (
              <Accordion
                isCollapsible={workspace?.workspace_type === "IFRAME_EMBED" ? true : false}
                key={workspace._id}
                headerContent={() => (
                  <div className="flex w-full items-center gap-4 sm:gap-10 flex-wrap">
                    <div className="flex items-center gap-4 mr-auto">
                      <div className="w-10 h-10 relative overflow-hidden rounded" style={{ backgroundColor: workspace.top_bar_color }}>
                        <img
                          className="w-full h-full object-contain"
                          src={workspace.image_favicon?.includes(".s3.") ? workspace.image_favicon + "?" + Date.now() : workspace.image_favicon?.includes("data:image/") ? workspace.image_favicon : baseUrl + workspace.image_favicon}
                          alt=""
                          onError={(e) => (e.target.src = `${noFavicon}`)}
                        />
                      </div>
                      <div className="font-semibold text-slate-800 text-base">{workspace?.name}</div>
                    </div>
                    {workspace?.workspace_type === "JWT_FULL_EMBED" && (
                      <div className="flex items-center gap-2 mx-2">
                        <div className="text-sm text-slate-600">Domo Role:</div>
                        <SelectMenu
                          classes={"w-52"}
                          options={roles}
                          setOption={(e) => manageUserRole(e, workspace._id, workspace?.user)}
                          startIndex={roles.findIndex((rl) => rl.value === workspace?.user?.type)}
                        />
                      </div>
                    )}
                    <div className="flex flex-wrap gap-2">
                      <div className="text-xs font-medium h-6 flex items-center justify-center px-2 bg-slate-200/50 rounded-md text-slate-500">{workspace?.workspace_type === "IFRAME_EMBED" ? "Dashboard Embed" : "Edit Embed"}</div>
                    </div>
                  </div>
                )}>
                <div className="py-3 px-3 bg-white">
                  <Tabs
                    changeParams={false}
                    tabs={[
                      {
                        name: "Individual",
                        icon: WindowIcon,
                        component: (
                          <WorkspaceIndividualPermission
                            workspaceId={workspace._id}
                            userId={id}
                          />
                        ),
                      },
                      {
                        name: "Groups",
                        icon: ClipboardDocumentListIcon,
                        component: (
                          <WorkspaceGroupListOldVersion
                            pageName="user"
                            workspaceId={workspace._id}
                            userId={id}
                            viewUsers={false}
                            viewOnly={true}
                            workspaceDetails={workspace}
                            workspaceUsers={workspaceUsers}
                            userDetails={userDetails}
                            workspace={allWorkspaces.find((wrk) => wrk._id === workspace._id)}
                          />
                        ),
                      },
                    ]}></Tabs>
                </div>
              </Accordion>
            ))}
          </div>
        )
      ) : (
        <Preloader />
      )}

      <Modal
        title={`Assign Workspace`}
        isOpen={workspaceModalOpen}
        onCancel={() => setWorkspaceModalOpen(false)}
        onSuccess={userAssignToWorkspace}
        overflow={true}>
        <div className="relative min-h-[200px] py-4">
          <MultiSelectObject
            defaultOptions={allWorkspaces?.map((workspace) => {
              return { key: workspace._id, value: workspace.name, selected: selectedWorkspace.includes(workspace?._id), object: workspace };
            })}
            searchableFields={["name"]}
            title="Assign workspace"
            onChange={handleSelectedWorkspace}
          />
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, { getUserWorkspaceData, getOperators, manageUserRole })(UserWorkspaceList);
