import { ChevronDownIcon, WindowIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import AddPermissionForm from "src/components/PageForm/AddPermissionForm";
import Button from "src/components/Shared/Buttons/Button";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";
import Members from "src/components/Shared/Members";
import { classNames } from "src/helpers/classNames";
import { operatorNameConverter } from "src/helpers/operators/operatorNameConverter";
import QuickMenu from "src/components/Shared/QuickMenu";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import Modal from "src/components/Shared/Modal";
import { apiRequest } from "src/async/apiUtils";

const WorkspaceGroupItemOldversion = ({ viewOnly = false, pages, workspaceId, workspaceDetails, refresh = () => {}, users, user, groups, group, setGroups, index, ...props }) => {
  const [hideDetails, setHideDetails] = useState(true);
  const [items, setItems] = useState([]);
  const [deleteId, setDeleteId] = useState(null);

  const permissionJson = {
    _id: null,
    page_id: null,
    filters: [
      {
        column_name: "",
        operator_id: null,
        column_value: "",
        trusted_attribute: "",
      },
    ],
  };

  const handleDeleteGroup = async () => {
    await apiRequest("delete", `/workspaces/${workspaceId}/groups/${deleteId}`);
    setDeleteId(null);
    refresh();
  };

  const addNewPermission = (e) => {
    e.preventDefault();
    setGroups(
      groups.map((grp, i) => {
        if (index === i) {
          return {
            ...grp,
            // _id: grp._id,
            permissions: [...grp.permissions, permissionJson],
          };
        } else {
          return grp;
        }
      })
    );
  };

  // const reorder = (list, startIndex, endIndex) => {
  //   const result = Array.from(list);
  //   const [removed] = result.splice(startIndex, 1);
  //   result.splice(endIndex, 0, removed);

  //   return result;
  // };

  /* const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(groups[index].permissions, result.source.index, result.destination.index);
    setGroups(
      groups.map((grp, i) => {
        if (index === i) {
          return { ...grp, permissions: items };
        } else {
          return grp;
        }
      })
    );
  }; */

  useEffect(() => {
    let defaultItems = [];
    if (authorizeUserComponentAccess(user, workspaceId, "user", ["create", "update", "delete"], true)) {
      defaultItems.push({
        name: "Manage users",
        onClick: () => {
          props.setIsAddUserModal(!props.isAddUserModal);
          props.setCurrentGroupId(groups[index]._id);
          props.setCurrentGroupUserId(groups[index].user_id);
        },
      });
    }
    if (authorizeUserComponentAccess(user, workspaceId, "group", ["delete"]) && !viewOnly) {
      defaultItems.push({
        name: "Delete group",
        hideWhen: false,
        onClick: () => setDeleteId(groups[index]._id),
        color: "text-red-500",
      });
    }
    setItems(defaultItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, viewOnly]);

  return (
    <>
      <div className="relative bg-white shadow border border-slate-200 rounded-md">
        <div className="flex items-center justify-between py-4 px-6 gap-10">
          <div className="flex items-center gap-x-3">
            <p className="font-semibold text-slate-700 truncate">{groups[index].name}</p>
            {authorizeUserComponentAccess(user, workspaceId, "group", ["update", "delete"]) && (
              <QuickMenu
                position="RIGHT"
                items={items}
              />
            )}
          </div>
          <div className="flex items-center">
            <div className="flex gap-x-5 flex-row-reverse sm:flex-row justify-end sm:pr-4">
              {groups[index].permissions.length ? (
                <div className="hidden sm:flex items-center py-1 px-2 text-sm bg-slate-100 text-slate-500 rounded">
                  {groups[index].permissions.length} Pages
                  <WindowIcon
                    className={classNames("ml-2 h-5 w-5 transition-all duration-150 ")}
                    aria-hidden="true"
                  />
                </div>
              ) : null}
              {groups[index].users !== undefined ? (
                <div
                  style={{ pointerEvents: viewOnly ? "none" : "auto" }}
                  onClick={() => {
                    props.setIsAddUserModal(!props.isAddUserModal);
                    props.setCurrentGroupId(groups[index]._id);
                    props.setCurrentGroupUserId(groups[index].user_id);
                  }}
                  className="relative flex gap-x-3 cursor-pointer hover:underline">
                  <Members group={groups[index]} />
                </div>
              ) : null}
            </div>
            <div className="ml-auto flex items-center gap-5">
              <Button
                onClick={() => setHideDetails(!hideDetails)}
                version="secondary"
                className="!py-1 !px-1 !border-0 !text-slate-300 !outline-none !ring-0 !ring-transparent !shadow-none">
                <ChevronDownIcon
                  className={classNames("h-5 w-5 transition-all duration-150 ", hideDetails ? "-rotate-90" : "")}
                  aria-hidden="true"
                />
              </Button>
            </div>
          </div>
        </div>
        <div className={classNames("relative ", !hideDetails ? "max-h-[2000px] py-4 px-6 border-t border-slate-200" : "overflow-hidden max-h-[0px]")}>
          <div className="space-y-1">
            {groups[index].permissions.length > 0 &&
              (authorizeUserComponentAccess(user, workspaceId, "group", ["update"]) ? (
                // <DragDropContext onDragEnd={onDragEnd}>
                //   <Droppable droppableId="droppable">
                //     {(provided, snapshot) => (
                <div
                // {...provided.droppableProps}
                // ref={provided.innerRef}
                >
                  {groups[index].permissions.map((count, i) => {
                    return (
                      <Fragment key={i + "_" + groups[index]._id}>
                        {/* <Draggable
                          draggableId={`${i}`}
                          index={i}>
                          {(provided, snapshot) => ( */}
                        <AddPermissionForm
                          viewOnly={viewOnly}
                          isGroup={true}
                          // innerRef={provided.innerRef}
                          // draggableProps={provided.draggableProps}
                          // dragHandleProps={provided.dragHandleProps}
                          key={i + "_" + groups[index]._id}
                          index={i}
                          pages={pages}
                          operators={props.operators?.map((operator) => {
                            return { ...operator, name: operatorNameConverter(operator.name), id: operator._id };
                          })}
                          state={groups[index].permissions}
                          setState={(e) => {
                            const updatedGroups = groups.map((grp, grpI) =>
                              index !== grpI
                                ? grp
                                : {
                                    ...grp,
                                    permissions: e,
                                  }
                            );

                            setGroups(updatedGroups);
                          }}
                        />
                        {/* )} */}
                        {/* </Draggable> */}
                      </Fragment>
                    );
                  })}
                  {/* {provided.placeholder} */}
                </div>
              ) : (
                //     )}
                //   </Droppable>
                // </DragDropContext>
                groups[index].permissions.map((count, i) => {
                  return (
                    <AddPermissionForm
                      isGroup={true}
                      viewOnly={viewOnly}
                      disabled={true}
                      key={i + "_" + groups[index]._id}
                      index={i}
                      pages={pages}
                      operators={props.operators?.map((operator) => {
                        return { ...operator, name: operatorNameConverter(operator.name), id: operator._id };
                      })}
                      state={groups[index].permissions}
                    />
                  );
                })
              ))}
          </div>
          {authorizeUserComponentAccess(user, workspaceId, "group", ["update"]) && !viewOnly && (
            <div className="w-full flex justify-end mb-[2px] px-12">
              <Button
                version="secondary"
                onClick={addNewPermission}>
                <div className="flex gap-x-3 items-center">
                  Add Page
                  <IconsWithPlus
                    strokeColor={"stroke-highlightColor"}
                    item={{ icon: WindowIcon }}
                  />
                </div>
              </Button>
            </div>
          )}
        </div>
      </div>
      <Modal
        title="Group"
        secondaryTitle="Delete"
        isOpen={!!deleteId}
        onCancel={() => setDeleteId(null)}
        onSuccess={handleDeleteGroup}
        defaultOptions={{
          onSuccessButtonText: "Delete",
        }}>
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete {groups[index].name}?</div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    operators: Object.values(state.operators),
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, {})(WorkspaceGroupItemOldversion);
