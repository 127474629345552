import { RectangleStackIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getWorkspaceData } from "src/actions/workspace";
import { getPageCredentialOptions } from "src/actions/page";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import Search from "src/components/Shared/Search";
import TRHeader from "src/components/Shared/Table/TRHeader";
import Table from "src/components/Shared/Table/Table";
import TableOptions from "src/components/Shared/TableOptions";
import WorkspaceEntry from "src/components/Workspaces/WorkspaceEntry";
import WorkspacesAddModal from "src/components/Workspaces/WorkspacesModals/WorkspacesAddModal";
import useFetch from "src/hooks/useFetch";
import ButtonDropdown from "../Shared/Buttons/ButtonDropdown";
import Button from "src/components/Shared/Buttons/Button";

const WorkspaceList = ({ user, viewOnly = false, getPageCredentialOptions, credentials = [] }) => {
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");

  const [showAddModal, setShowAddModal] = useState(false);
  const [workspaceType, setWorkspaceType] = useState("");
  const [isAvailableEditEmbedSettings, setIsAvailableEditEmbedSettings] = useState(false);

  const {
    response: { data: workspaces, meta },
    status: { done: workspacesLoaded },
    refreshData: refreshWorkspaces,
  } = useFetch(`/workspaces/list`, { method: "post", query: {}, data: { includes: ["groups", "users", "pages"], limit: limit || 20, offset: offset || 0, keyword: keyword || "" } });

  useEffect(() => {
    refreshWorkspaces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, keyword]);

  const handlePaginationChange = ({ limit, offset }) => {
    setLimit(limit);
    setOffset(offset);
  };

  const loadPageCreds = async () => {
    // setCredLoading(true);
    const ac = new AbortController();
    try {
      await getPageCredentialOptions({}, ac.signal);
    } catch (error) {
      // console.dir("ERROR:", error);
    }
    return () => ac.abort();
  };

  useEffect(() => {
    loadPageCreds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    for (const credential of credentials) {
      if (credential?.jwt_secret_count > 0 && credential?.embed_url && credential?.key_attribute) {
        setIsAvailableEditEmbedSettings(true)
      } else {
        setIsAvailableEditEmbedSettings(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials]);

  const workspaceDropdownOptions = [
    {
      _id: 1,
      name: "Embed Code",
      type: "",
      onClick: () => {
        setWorkspaceType("IFRAME_EMBED");
        setShowAddModal(true);
      },
      visible: true,
    },
    {
      _id: 2,
      name: "Domo Instance Embed",
      type: "",
      onClick: () => {
        setWorkspaceType("JWT_FULL_EMBED");
        setShowAddModal(true);
      },
      visible: true,
    },
  ];

  return (
    <>
      <TableOptions
        leftContent={
          <Search
            keyword={keyword}
            setKeyword={setKeyword}
          />
        }
        rightContent={isAvailableEditEmbedSettings ? (
          <ButtonDropdown
            xPosition={"right"}
            data={workspaceDropdownOptions.filter((item) => item.visible)}
            buttonIcon={{ icon: RectangleStackIcon }}
          />
        ) : (
          <Button
            version="secondary"
            onClick={() => {
              setWorkspaceType("IFRAME_EMBED");
              setShowAddModal(true);
            }}>
            <RectangleStackIcon className="h-6"/>
          </Button>
        )}
      />
      <Table
        tableHeader={
          <TRHeader>
            <th
              scope="col"
              className="rounded-l-xl py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Name
            </th>
            <th
              scope="col"
              className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Pages
            </th>
            <th
              scope="col"
              className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Groups
            </th>
            <th
              scope="col"
              className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Users
            </th>
            <th
              scope="col"
              className="rounded-r-xl relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
          </TRHeader>
        }
        colSpan="5"
        loaded={workspacesLoaded}
        dataExists={workspaces.length > 0}>
        {workspaces.map((workspace) => (
          <WorkspaceEntry
            viewOnly={viewOnly}
            key={workspace._id}
            workspace={workspace}
          />
        ))}
      </Table>
      <PaginationFooter
        itemName="Workspace records"
        limit={limit}
        offset={offset}
        count={meta?.count}
        onChange={handlePaginationChange}
      />

      <WorkspacesAddModal
        isOpen={showAddModal}
        workspaceType={workspaceType}
        onCancel={() => setShowAddModal(false)}
        onSuccess={() => {
          setShowAddModal(false);
          refreshWorkspaces();
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    credentials: state.pageCredentialOptions,
  };
};

export default connect(mapStateToProps, { getWorkspaceData, getPageCredentialOptions })(WorkspaceList);
