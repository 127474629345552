import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { deleteUser } from "src/actions/user";
import { fetchData } from "src/async/fetch";
import Button from "src/components/Shared/Buttons/Button";
import DataExport from "src/components/Shared/DataExport";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";
import Modal from "src/components/Shared/Modal";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import Search from "src/components/Shared/Search";
import Table from "src/components/Shared/Table/Table";
import TRHeader from "src/components/Shared/Table/TRHeader";
import TableOptions from "src/components/Shared/TableOptions";
import UserEntry from "src/components/Users/List/UserEntry";
import { apiUrl } from "src/config/host";
import { getUrlParams } from "src/helpers/getUrlParams";
import { urlBuilder } from "src/helpers/urlBuilder";
import useFetch from "src/hooks/useFetch";
import { UsersIcon } from "@heroicons/react/24/outline";
import Input from "src/components/Shared/Forms/Inputs/Input";
import MultiSelectObject from "src/components/Shared/Forms/Selects/MultiSelectObject";
import { addUser, getGroupData, manageUserRole } from "src/actions/user";
import { apiRequest } from "src/async/apiUtils";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";

const WorkspaceUsersList = ({ user = {}, ...props }) => {
  const roles = [
    { key: "Participant", value: "participant" },
    { key: "Editor", value: "editor" },
    { key: "Privileged", value: "privileged" },
    { key: "Admin", value: "admin" },
  ];
  const [deleteId, setDeleteId] = useState(null);
  const [addUser, setAddUser] = useState(false);
  const [addExistingUser, setAddExistingUser] = useState(false);
  const [state, setState] = useState({
    name: "",
    email: "",
    password: "",
    confirm_password: "",
    image: "",
    type: "participant",
    notifications: false,
    update: true,
  });
  // Search
  const [keyword, setKeyword] = useState("");
  const [status, setStatus] = useState("");
  const [group] = useState("");
  const [groupList, setGroupList] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  // Pagination
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  // All User
  const [userLoaded, setUserLoaded] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedExistingUserGroups, setSelectedExistingUserGroups] = useState([]);
  const [successModalStart, setSuccessModalStart] = useState(false);

  let {
    params: { workspaces: workspaceId },
  } = getUrlParams(window);

  const {
    response: { data: users, meta },
    status: { done: usersLoaded },
    refreshData: refreshUsers,
  } = useFetch(workspaceId && props.workspaceDetails?._id ? urlBuilder(`/workspaces/:workspace_id/users`, { workspace_id: workspaceId }) : "", { query: { limit: limit, offset: offset * limit, keyword: keyword, status: status, group: group } });

  const handlePaginationChange = ({ limit, offset }) => {
    setLimit(limit);
    setOffset(offset);
  };

  useEffect(() => {
    refreshUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, status, group]);

  useEffect(() => {
    refreshUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  // Load groups
  useEffect(() => {
    // const ac = new AbortController();

    const loadGroups = async (workspaceId) => {
      try {
        if (props.workspaceDetails?.workspace_type === "JWT_FULL_EMBED") {
          const res = await apiRequest("get", `/workspaces/${workspaceId}/domo-groups`, {
            queries: { auth_token: props.workspaceDetails?.auth_token },
          });

          if (res.data.status === 200) {
            setGroupList(res.data.data);
          }
        } else {
          await props.getGroupData({});
        }
      } catch (error) {
        // console.dir("ERROR:", error.message);
      }
    };

    if (workspaceId) {
      loadGroups(workspaceId);
    }

    // return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId, props.workspaceDetails?.auth_token, props.workspaceDetails?.workspace_type]);

  useEffect(() => {
    if (props.workspaceDetails?.workspace_type !== "JWT_FULL_EMBED") {
      setGroupList(props.groups.filter((grp) => grp?.workspace_id?._id === props.workspaceDetails?._id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.groups, props.workspaceDetails]);

  useEffect(() => {
    const loadUsers = async () => {
      const res = await apiRequest("get", "users/list", { queries: { type: "user/editor" } });

      if (res.status === 200) {
        setAllUsers(res.data.data);
      }

      setUserLoaded(true);
    };

    loadUsers();
  }, []);

  const removeUser = async (e) => {
    if (e) {
      e.preventDefault();
    }

    try {
      setSuccessModalStart(true);
      const res = await fetchData("POST", `${apiUrl}/workspaces/${workspaceId}/users/remove`, {
        id: deleteId,
        auth_token: props.workspaceDetails?.auth_token,
      });
      const { status, message } = await res.json();
      if (status === 200) {
        setDeleteId(null);
        toast.success(message);
        setSuccessModalStart(false);
        refreshUsers();
      } else {
        toast.error(message);
      }
    } catch (error) {
      toast.error(error.message);
      setSuccessModalStart(false);
    }
  };

  const prepData = async () => {
    const params = { keyword: keyword, workspace_id: workspaceId };
    try {
      const res = await fetchData("GET", `${apiUrl}/workspaces/${workspaceId}/users`, params);
      const { data } = await res.json();

      let exportDoc = [];
      if (data.length > 0) {
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          let groupName = [];
          if (element.groups.length > 0) {
            for (let i = 0; i < element.groups.length; i++) {
              const grup = element.groups[i];
              groupName.push(grup.name);
            }
          }
          exportDoc.push({
            Name: element.name,
            Email: element.email,
            Type: element.type,
            Groups: groupName.toString(),
            "Date Created": moment(new Date(element.created_at)).format("MM/DD/YYYY HH:mm:ss"),
            "Last Login": element.last_login ? moment(new Date(element.last_login)).format("MM/DD/YYYY HH:mm:ss") : "n/a",
            "Is Active": element.active_status ? "true" : "false",
            "Deactivation Dated": !element.active_status ? moment(new Date(element.deactivated_at)).format("MM/DD/YYYY HH:mm:ss") : "n/a",
          });
        }
      }
      return exportDoc;
    } catch (error) {
      // console.dir(error.message);
    }
  };

  useEffect(() => {
    let updatedGroups = groupList.filter((group) => selectedGroups.includes(group._id));
    setState({
      ...state,
      groups: updatedGroups,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroups]);

  const handleSelectedGroups = (groups) => {
    let filteredGroups = groups.filter((group) => group.selected);
    setSelectedGroups(filteredGroups.map((group) => group.key));
  };

  const onSubmit = async (users = null) => {
    try {
      setSuccessModalStart(true);
      const res = await fetchData("POST", `${apiUrl}/workspaces/${workspaceId}/users/add`, {
        users: Array.isArray(users) ? users.map((user) => ({ ...user, groups: selectedGroups })) : [{ ...state, groups: selectedGroups }],
        auth_token: props.workspaceDetails?.auth_token,
        existing: Array.isArray(users) ? true : false,
        isDomo: props.workspaceDetails?.workspace_type === "JWT_FULL_EMBED",
        workspace_id: props.workspaceDetails?._id,
      });
      const { status, message } = await res.json();
      if (status === 200) {
        toast.success(message);
        refreshUsers();
        setAddUser(false);
        setAddExistingUser(false);
        setSelectedUsers([]);
        setSelectedExistingUserGroups([]);
      } else {
        toast.error(message);
      }
      setSuccessModalStart(false);
    } catch (error) {
      toast.error(error.message);
      setSuccessModalStart(false);
    }
  };

  const manageUserRole = async (e, user) => {
    try {
      const data = await props.manageUserRole({
        id: user._id,
        type: e.value,
        auth_token: props.workspaceDetails?.auth_token,
        workspace_id: workspaceId,
      });
      toast.success(data);
    } catch (error) {
      refreshUsers();
      toast.error(error.message);
    }
  };

  return (
    <>
      <TableOptions
        leftContent={
          <Search
            keyword={keyword}
            setKeyword={setKeyword}
          />
        }
        middleContent={
          <SelectMenu
            options={[
              { key: "All Status", value: "" },
              { key: "Active", value: "true" },
              { key: "Inactive", value: "false" },
            ]}
            setOption={(option) => {
              if (option.value) {
                setStatus(option.value === "true");
              } else {
                setStatus(option.value);
              }
            }}
          />
        }
        rightContent={
          authorizeUserComponentAccess(user, workspaceId, "user", ["create"]) && (
            <Button
              version="secondary"
              onClick={() => setAddUser(true)}>
              <IconsWithPlus
                strokeColor={"stroke-highlightColor"}
                item={{ icon: UsersIcon }}
              />
            </Button>
          )
        }
      />
      <Table
        tableHeader={
          <TRHeader>
            <th
              scope="col"
              className="rounded-l-xl py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Name
            </th>
            <th
              scope="col"
              className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Email
            </th>
            <th
              scope="col"
              className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Status
            </th>
            {props.workspaceDetails?.workspace_type === "JWT_FULL_EMBED" && (
              <th
                scope="col"
                className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Domo Role
              </th>
            )}
            <th
              scope="col"
              className="rounded-r-xl relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
          </TRHeader>
        }
        colSpan="4"
        setDeleteId={setDeleteId}
        deleteId={deleteId}
        loaded={usersLoaded}
        dataExists={users?.length > 0}>
        {users?.length > 0 &&
          users.reduce((filteredUsers, user) => {
            return filteredUsers.length < limit
              ? [
                  ...filteredUsers,
                  <UserEntry
                    key={user._id}
                    user={user}
                    deleteId={deleteId}
                    setDeleteId={setDeleteId}
                    refreshUsers={refreshUsers}
                    manageUserRole={manageUserRole}
                    workspaceDetails={props.workspaceDetails}
                  />,
                ]
              : filteredUsers;
          }, [])}
      </Table>
      <PaginationFooter
        itemName="User records"
        count={users?.length && meta?.count}
        loaded={usersLoaded}
        limit={limit}
        offset={offset}
        tabCount={usersLoaded && Math.ceil(meta?.count / limit)}
        onChange={handlePaginationChange}
      />
      <DataExport
        title="user_list_report"
        headings={["Name", "Email", "Type", "Groups", "Date Created", "Last Login", "Is Active", "Deactivated Date"]}
        prepData={prepData}
      />
      <Modal
        title="User"
        secondaryTitle="Delete"
        isOpen={!!deleteId}
        onCancel={() => setDeleteId(null)}
        onSuccess={removeUser}
        defaultOptions={{
          onSuccessButtonText: "Delete",
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: successModalStart,
        }}>
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete {usersLoaded && users?.find((user) => user._id === deleteId)?.name}?</div>
      </Modal>
      <Modal
        title="Workspace"
        secondaryTitle={`${props.workspaceDetails?.name}/Create User`}
        isOpen={addUser}
        onCancel={() => setAddUser(false)}
        onSuccess={onSubmit}
        defaultOptions={{
          onSuccessButtonText: "Submit",
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: successModalStart,
        }}>
        <div className="relative w-full grid grid-cols-2 gap-4">
          <div className="relative w-full">
            <Input
              name="fname"
              label="Full Name"
              type={"text"}
              value={state.name}
              autoComplete={"new-fname"}
              onChange={(e) =>
                setState({
                  ...state,
                  name: e.target.value,
                })
              }
            />
          </div>
          <div className="relative w-full">
            <Input
              name="email"
              label="Email"
              type={"email"}
              value={state.email}
              autoComplete={"new-email"}
              onChange={(e) =>
                setState({
                  ...state,
                  email: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="relative w-full mt-4">
          <MultiSelectObject
            defaultOptions={groupList?.map((group) => {
              return { key: group.id || group._id, value: group.name, selected: false, object: group };
            })}
            searchableFields={["name"]}
            title="Assign groups"
            onChange={handleSelectedGroups}
          />
        </div>

        <div className="relative w-full mt-4 flex">
          <Toggle
            checked={state.update}
            onChange={(e) =>
              setState({
                ...state,
                update: e,
              })
            }
          />
          <div className="ml-3 -mt-[2px] text-sm text-gray-600">
            <p className="p-0">Send welcome email on setup</p>
            <p className="p-0 text-gray-400/80">Disabling this will allow you to manually enter a password</p>
          </div>
        </div>

        {!state.update && (
          <div className="relative w-full grid mt-4 grid-cols-2 gap-4">
            <div className="relative w-full">
              <Input
                name="fname"
                label="Password"
                type={"password"}
                value={state.password}
                autoComplete={"new-password"}
                onChange={(e) =>
                  setState({
                    ...state,
                    password: e.target.value,
                  })
                }
              />
            </div>
            <div className="relative w-full">
              <Input
                name="fname"
                label="Confirm Password"
                type={"password"}
                value={state.confirm_password}
                autoComplete={"new-password"}
                onChange={(e) =>
                  setState({
                    ...state,
                    confirm_password: e.target.value,
                  })
                }
              />
            </div>
          </div>
        )}

        {props.workspaceDetails?.workspace_type === "JWT_FULL_EMBED" && (
          <div className="relative w-full mt-4">
            <label className="flex justify-between text-sm font-medium text-gray-700 mb-1">Domo Role</label>
            <SelectMenu
              options={roles}
              setOption={(e) =>
                setState({
                  ...state,
                  type: e.value,
                })
              }
              startIndex={roles.findIndex((rl) => rl.value === state.role) > 0 ? roles.findIndex((rl) => rl.value === state.role) : 0}
            />
          </div>
        )}

        {user?.type === "admin" && (
          <div className="relative w-full flex mt-4">
            <Button
              styleType="primary"
              className="!p-0 !bg-transparent !text-highlightColor underline !border-0 !font-semibold !text-sm"
              onClick={() => {
                setAddExistingUser(true);
                setAddUser(false);
              }}>
              Add Existing User
            </Button>
          </div>
        )}
      </Modal>
      <Modal
        title="Workspace"
        secondaryTitle={`${props.workspaceDetails?.name}/ Add Existing User`}
        isOpen={addExistingUser}
        onCancel={() => setAddExistingUser(false)}
        onSuccess={() => {
          onSubmit(allUsers.filter((user) => selectedUsers.includes(user._id)).map((user) => ({ ...user, groups: selectedExistingUserGroups })));
        }}
        defaultOptions={{
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: successModalStart,
        }}>
        <MultiSelectObject
          defaultOptions={
            userLoaded &&
            allUsers
              ?.filter((singleUser) => !users?.map((user) => user._id).includes(singleUser._id))
              .map((user) => {
                return { key: user._id, value: user.name, selected: selectedUsers.includes(user._id), object: user };
              })
          }
          searchableFields={["name", "email"]}
          title="Assign users"
          onChange={(allSelectedUsers) => {
            setSelectedUsers(allSelectedUsers.filter((user) => user.selected).map((user) => user.object._id));
          }}
        />
        {/* <div className="relative w-full">
          <MultiSelectObject
            defaultOptions={groupList.map((group) => {
              return { key: group._id, value: group.name, selected: false, object: group };
            })}
            searchableFields={["name"]}
            title="Assign groups"
            onChange={(groups) => {
              setSelectedExistingUserGroups(
                groups.filter(group => group.selected).map(group => group.object._id)
              )
            }}
          />
        </div> */}
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    groups: Object.values(state.groups),
  };
};

export default connect(mapStateToProps, {
  deleteUser,
  getGroupData,
  addUser,
  manageUserRole,
})(WorkspaceUsersList);
