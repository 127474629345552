import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addUser, getGroupData } from "src/actions/user";
import BackIndicator from "src/components/Navigation/BackIndicator";
import Button from "src/components/Shared/Buttons/Button";
import { H1 } from "src/components/Shared/Text/Headers";
import AddUserForm from "src/components/Users/Add/AddUserForm";
import { apiRequest } from "src/async/apiUtils";

const ManageUserSection = ({ pages, operators, groups, addUser, site, ...props }) => {
  const [formCount, setFormCount] = useState([0]);
  const [userState, setUserState] = useState({});
  const [workspaces, setWorkspaces] = useState([])
  const [workspaceGroups, setWorkspaceGroups] = useState([])
  const [isRefresh] = useState(false);
  const [successButtonStart, setSuccessButtonStart] = useState(false)

  const navigate = useNavigate();
  const isAdmin = window.location.pathname.includes("admins/add");

  // Load Workspaces
  useEffect(() => {
    const loadWorkspaces = async () => {
      const res = await apiRequest('post', '/workspaces/list', {
        body: { includes: ['groups', 'users', 'pages'] }
      })

      const data = res.data

      if (data.status === 200) {
        setWorkspaces(data.data.map(workspace => ({ ...workspace, selected: false })))
      }
    }

    loadWorkspaces()
  }, [])

  // Load groups
  useEffect(() => {
    // const ac = new AbortController();

    if (workspaces.length) {
      const loadGroups = async () => {
        try {
          const res = await apiRequest(
            'post', 
            '/workspaces/domo-workspace-groups',
            { body: { workspaceIds: workspaces.map(item => item._id) } }
          )

          const data = res.data

          if (data.status === 200) {
            setWorkspaceGroups(data.data)
          }

          // await props.getGroupData({}, ac.signal);
        } catch (error) {
          // console.dir('error', error);
        }
      };

      loadGroups()
    }

    // return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh, workspaces.length]);

  const onSubmit = async () => {
    try {
      setSuccessButtonStart(true)

      let userData = [];

      Object.values(userState).forEach((user) => {
        userData.push({ ...user, type: isAdmin ? "admin" : "user" });
      });

      await addUser({
        users: userData,
        workspaceIds: workspaces.filter(workspace => workspace.selected).map(
          item => item._id || item.key
        ),
        groupIds: groups.filter(item => item.selected).map(item => item._id || item.id)
      });

      setSuccessButtonStart(false)

      if (isAdmin) {
        return navigate("/admins");
      } else {
        return navigate("/users");
      }
    } catch (error) {
      setSuccessButtonStart(false)
      toast.error(error.message);
    }
  };

  return (
    <>
      <H1 item={{ icon: BackIndicator }}>
        <p className="text-gray-300 font-light capitalize">{isAdmin ? "admin" : "user"}s/</p>Create
      </H1>

      <div className="my-3 w-full flex items-center justify-between">
        <h3 className="pl-2 text-xl font-medium text-gray-500">Complete the new {isAdmin ? "admin" : "user"} form</h3>
      </div>
      {formCount.map((count, i) => {
        return (
          <AddUserForm
            key={i}
            isAdmin={isAdmin}
            index={count}
            formCount={formCount}
            setFormCount={setFormCount}
            state={userState}
            setState={setUserState}
            groups={workspaceGroups}
            workspaces={workspaces}
            setWorkspaces={setWorkspaces}
          />
        );
      })}

      <div className="w-full flex justify-end border-t-[1px] border-t-gray-200 mt-5 pt-3 gap-x-3">
        <Button
          version="gray"
          type="button"
          onClick={() => {}}>
          Clear all
        </Button>
        <Button
          type="button"
          onClick={onSubmit}
          disabled={successButtonStart}
          loading={successButtonStart}
        >
          Submit
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    groups: Object.values(state.groups),
    site: state.site,
  };
};

export default connect(mapStateToProps, { getGroupData, addUser })(ManageUserSection);
