import { connect } from "react-redux";
import { Link } from "react-router-dom";

const LandingPage = ({ from, workspaceId, pageId, pageName, navigationBlocks }) => {
  return (
    <div className="p-8 w-full">
      <div className="grid grid-cols-3 gap-x-4 gap-y-10 mb-10">
        {navigationBlocks.map((navigationBlock, index) => {
          return (
            <div
              className="relative"
              key={index}>
              <div className="p-2 space-y-2 hover:ring-2 ring-sky-600 rounded transition-all duration-200">
                <div className="text-xl font-bold text-slate-700">{navigationBlock.title}</div>
                <div className="w-full overflow-hidden rounded">
                  <img
                    className=""
                    height={navigationBlock.height}
                    width={navigationBlock.width}
                    src={navigationBlock.image + "?" + Date.now()}
                    alt=""
                  />
                </div>
              </div>
              {navigationBlock.links.length > 0 && (
                <div className="p-2 space-y-2">
                  {navigationBlock.links.map((link, index2) => {
                    return (
                      <div
                        className="flex"
                        key={index + "_" + index2}>
                        <Link
                          to={from === 'previous' ? '#' : `/workspace/${workspaceId}/link/${link.page_id}`}
                          className="text-base font-semibold text-sky-600 hover:text-sky-800 transition-all duration-200">
                          {link.link_text}
                        </Link>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(LandingPage);
