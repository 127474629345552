import React, { useEffect } from "react";
import { useState } from "react";
import FrontLabeledInput from "src/components/Shared/Forms/Inputs/FrontLabeledInput";
import Input from "src/components/Shared/Forms/Inputs/Input";
import { randomHashString } from "src/helpers/randomHashString";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";

const WorkspaceIntegrationClientsInstanceItem = ({ user, workspaceId, state, setState = () => {} }) => {
  const [viewOnly, setViewOnly] = useState(true);

  useEffect(() => {
    let authResponse = authorizeUserComponentAccess(user, workspaceId, "integration", ["update"]);
    setViewOnly(!authResponse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full h-full relative bg-gray-50/70 py-3 px-4">
      <div className="relative w-full space-y-4">
        <div className="relative w-full mb-4">
          <Input
            name="new-subscriber-url"
            label="Subscriber Instance URL"
            autoComplete={"new-subscriber-url" + state?._id}
            disabled={viewOnly}
            value={state.subscriber_instance_url || ""}
            onChange={(e) => {
              setState({ ...state, subscriber_instance_url: e.target.value });
            }}
          />
        </div>
        <div className="relative w-full mb-4">
          <Input
            name="new-attribute"
            label="Key Attribute Value"
            disabled={viewOnly}
            autoComplete={"new-attribute" + state?._id}
            value={state.key_attribute_value || ""}
            onChange={(e) => {
              setState({ ...state, key_attribute_value: e.target.value });
            }}
          />
        </div>
        <div className="relative w-full mb-4">
          <label className="flex justify-between text-sm font-medium text-gray-700 mb-1">Authentication</label>
          <div className="grid grid-cols-2 gap-4">
            <FrontLabeledInput
              name="new-clientId"
              autoComplete={"new-password"}
              label="Client ID"
              type="password"
              value={state.client_id || ""}
              disabled={viewOnly}
              placeholder={randomHashString(state.client_id_count)}
              onChange={(e) => {
                setState({ ...state, client_id: e.target.value });
              }}
            />
            <FrontLabeledInput
              name="new-clientSecret"
              autoComplete={"new-clientSecret" + state?._id}
              label="Client Secret"
              type="password"
              placeholder={randomHashString(state.client_secret_count)}
              value={state.client_secret || ""}
              disabled={viewOnly}
              onChange={(e) => {
                setState({ ...state, client_secret: e.target.value });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceIntegrationClientsInstanceItem;
