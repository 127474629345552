import { ArrowDownTrayIcon, CloudArrowDownIcon, DocumentTextIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { addBulkUser } from "src/actions/user";
import TextAccordion from "src/components/Shared/Accordions/TextAccordion";
import Button from "src/components/Shared/Buttons/Button";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import { H4 } from "src/components/Shared/Text/Headers";
import { filePath } from "src/config/host";
import { convertToBase64 } from "src/helpers/image";

const BulkUpload = ({ addBulkUser, users, creds, type, limit, status, setStatus, group, setGroup, keyword, setKeyword, offset, groups, ...props }) => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(true);

  const navigate = useNavigate();

  const handleFileChange = (e) => {
    if (e.target.files[0]?.name) {
      setFileName(e.target.files[0]?.name);
      convertToBase64(e.target.files[0], setFile);
    }
  };

  const onSubmit = async () => {
    try {
      const message = await addBulkUser({ file, sendWelcomeEmail });
      toast.success(message);

      return navigate("/users");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="w-full grid gap-5">
        <div className="relative">
          <div className="max-w-3xl relative border-2 border-dashed border-slate-300 rounded-3xl p-5 overflow-hidden flex flex-col justify-center items-center">
            <input
              type="file"
              onChange={handleFileChange}
              accept=".csv"
              className="absolute top-0 right-0 h-[600px] w-[600px] z-10 opacity-0 cursor-pointer"
            />
            {fileName ? (
              <div className="font-medium flex focus:outline-none text-gray-400 border-leftNavColor cursor-pointer sm:w-auto">
                <div className="flex items-center gap-x-2">
                  <DocumentTextIcon className="w-6 h-6" /> {fileName ? fileName : "No file chosen."}
                </div>
              </div>
            ) : (
              <div className="h-full w-full p-5 flex flex-col justify-center items-center relative">
                <div className="flex flex-col justify-center items-center space-y-4">
                  <CloudArrowDownIcon className="w-12 h-12 text-gray-400" />
                  <div className="text-gray-400 font-semibold">Drag & Drop file here</div>
                  <div className="h-[1px] bg-slate-200 w-full flex justify-center items-center !my-8">
                    <span className="text-gray-700 flex justify-center items-center w-8 h-8 rounded-full bg-slate-50 border border-slate-900/10 text-xs font-semibold uppercase">or</span>
                  </div>
                </div>
                <label htmlFor="bulk_upload-input">
                  <Button
                    version="secondary"
                    onClick={() => {}}>
                    Select file
                  </Button>
                </label>
                <input
                  type="file"
                  onChange={handleFileChange}
                  id="bulk_upload-input"
                  name="bulk_upload-input"
                  accept=".csv"
                  className="hidden"
                />
              </div>
            )}
          </div>
          <TextAccordion
            headerTextPosition="left"
            headerText="Advanced details">
            <div className="grid gap-y-6 py-3">
              <div className="flex md:flex-row flex-col gap-y-3 justify-between">
                <H4 caption="Use the outline to create your own CSV file.">Download a CSV template</H4>

                <a
                  href={filePath + "/upload-users.csv"}
                  className="flex font-medium underline focus:outline-none text-gray-400 border-leftNavColor cursor-pointer">
                  <div className="hover:bg-gray-200/20 flex py-2 px-4 rounded items-center gap-x-2">
                    <ArrowDownTrayIcon className="w-6 h-6" /> CSV Format
                  </div>
                </a>
              </div>
              <hr className="w-full" />
              <div className="flex md:flex-row flex-col gap-y-3 justify-between">
                <H4 caption='When disabled, users will need to follow the "reset password process" before logging in.'>Send welcome email on setup</H4>
                <div className="ml-2">
                  <Toggle
                    checked={sendWelcomeEmail}
                    onChange={setSendWelcomeEmail}
                  />
                </div>
              </div>
            </div>
          </TextAccordion>
        </div>

        <div className="relative flex flex-col justify-end items-start">
          <div className="w-full flex justify-end">
            <Button
              disabled={fileName === ""}
              onClick={onSubmit}>
              Upload file
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    creds: state.creds,
    site: state.site,
    groups: Object.values(state.groups),
  };
};

export default connect(mapStateToProps, { addBulkUser })(BulkUpload);
