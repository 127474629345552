import React from "react";
import { WindowIcon, GlobeAltIcon } from "@heroicons/react/24/outline";
import PageList from "src/components/Pages/PageList";
import { H1 } from "src/components/Shared/Text/Headers";
import Tabs from "src/components/Shared/Tabs/Tabs";

const pages = () => {
  const tabs = [
    { name: "All pages", icon: WindowIcon, component: <PageList pageName="Pages" /> },
    {
      name: "Global pages",
      icon: GlobeAltIcon,
      component: (
        <PageList
          pageName="Pages"
          isGlobal={true}
        />
      ),
    },
  ];
  return (
    <>
      <H1>Pages</H1>
      <Tabs tabs={tabs} />
    </>
  );
};

export default pages;
