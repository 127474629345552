import { useEffect, useState } from "react";
import { connect } from "react-redux";
import MultiSelectObject from "src/components/Shared/Forms/Selects/MultiSelectObject";
import Modal from "src/components/Shared/Modal";
// import useFetch from "src/hooks/useFetch";
import WorkspaceImportUserModal from "./WorkspaceImportUserModal";

const WorkspaceManageUsersModal = ({ workspaceDetails, title = "", secondaryTitle = "", user, defaultSelectedUsers = [], isOpen, onCancel = () => {}, onSuccess = () => {}, isPermissionPage = false, isImportModalOpen = false, setIsImportModalOpen = () => {}, sendWelcomeEmail = false, setSendWelcomeEmail = () => {}, password = '', setPassword = ()  => {}, confirmPassword  = '', setConfirmPassword = () => {}, hideCrossIcon = true, workspaceUsers = [], disableStatus = false, ...props }) => {
  const [selectedUsers, setSelectedUsers] = useState(defaultSelectedUsers || props.selectedUser);

  useEffect(() => {
    if(isOpen){
      setSelectedUsers(defaultSelectedUsers)
    }
  }, [ isOpen, defaultSelectedUsers ])

  useEffect(() => {
    if (Array.isArray(props.selectedUser) && props.selectedUser.length > 0) {
      setSelectedUsers(props.selectedUser)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ props.selectedUser?.length ])

  const handleSelectedUsers = (updatedSelectedUsers = []) => {
    let userIds = [];
    if (isPermissionPage) {
      if (user?.type === "admin" || (workspaceDetails?.user_permissions && workspaceDetails?.user_permissions?.permission_delete)) {
        userIds = [];
      } else {
        userIds = defaultSelectedUsers;
      }
    }
    for (let i = 0; i < updatedSelectedUsers.length; i++) {
      const updatedSelectedUser = updatedSelectedUsers[i];
      if (updatedSelectedUser.selected) {
        userIds.push(updatedSelectedUser.key);
      }
    }
    setSelectedUsers(userIds);
  };

  return (
    <>
      <Modal
        title={title}
        secondaryTitle={secondaryTitle}
        isOpen={isOpen}
        onCancel={onCancel}
        isOverflow={false}
        onSuccess={() => onSuccess(selectedUsers)}
        defaultOptions={{
          onSuccessButtonText: "Save",
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: disableStatus
        }}
        overflow
      >
        <div className="relative min-h-[200px] py-4">
          <MultiSelectObject
            defaultOptions={workspaceUsers.map((user) => {
              return { key: user._id || user.id, value: user.name, selected: selectedUsers.includes(user._id || user.id), object: user };
            })}
            searchableFields={["name", "email"]}
            drowdownWindowMaxWidth="max-w-[200px]"
            title="Assign users"
            onChange={handleSelectedUsers}
          />
        </div>
      </Modal>
      {/* Group import user modal open */}
      <WorkspaceImportUserModal 
        isImportModalOpen={isImportModalOpen}
        setIsImportModalOpen={setIsImportModalOpen}
        hideCrossIcon={hideCrossIcon}
        selectedUsers={selectedUsers}
        workspaceUsers={workspaceUsers}
        sendWelcomeEmail={sendWelcomeEmail}
        setSendWelcomeEmail={setSendWelcomeEmail}
        password={password}
        setPassword={setPassword}
        confirmPassword={confirmPassword}
        setConfirmPassword={setConfirmPassword}
        onSuccess={onSuccess}
        workspaceDetailsData={props.workspaceDetailsData}
        disableStatus={disableStatus}
      />
      
      {/* <Modal
        title="Workspace"
        secondaryTitle={`${props.workspaceDetailsData?.name} / Import User(s)`}
        isOpen={isImportModalOpen}
        defaultOptions={{
          onSuccessButtonText: 'Yes',
          onCancelButtonText: 'No'
        }}
        onSuccess={() => onSuccess(selectedUsers, true)}
        onCancel={() => onSuccess(selectedUsers, false)}
        hideCrossIcon
        overflow={true}
      >
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">
          The following people are not currently users in the portal. Would you like to add them? 
        </div>

        <div className="flex flex-col mt-5 pl-5 space-y-3">
          {
            workspaceUsers.filter(user => (
              selectedUsers.includes((user.id || user._id)) && !user.portalUser
            )).length ?
            workspaceUsers.filter(user => (
              selectedUsers.includes((user.id || user._id)) && !user.portalUser
            )).map(user => (
              <div className="text-sm text-black font-semibold">
                { user.name } (not a portal user)
              </div>
            )) : 'No users available to import'
          }
        </div>

        <div className="w-full px-2 flex my-5">
          <div className="ml-3 -mt-[2px] text-sm text-gray-600 w-full">
            <p className="p-0">Send welcome email on setup</p>
            <p className="p-0 text-gray-400/80">When disabled, users will need to follow the "reset password process" before logging in.</p>
          </div>

          <div className="flex items-center justify-end min-w-[120px]">
            <Toggle
              checked={sendWelcomeEmail}
              onChange={() => setSendWelcomeEmail(prev => !prev)}
            />
          </div>
        </div>

        {
          !sendWelcomeEmail &&
          <div className="flex my-5">
            <div className="sm:w-1/2 w-full px-2">
              <Input
                autoComplete="new-password"
                type="password"
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="sm:w-1/2 w-full px-2">
              <Input
                autoComplete="new-password"
                type="password"
                name="confirm-password"
                label="Confirm password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>
        }
      </Modal> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, {})(WorkspaceManageUsersModal);
