import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { manageWorkspaceSettings } from "src/actions/workspace";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import { urlBuilder } from "src/helpers/urlBuilder";
import useFetch from "src/hooks/useFetch";

const LandingPageSelector = ({ workspace, updateLayoutLandingPageId = () => {}, ...props }) => {
  const [landingPageOptions, setLandingPageOptions] = useState([]);
  const [layoutLandingPageId, setLayoutLandingPageId] = useState(workspace.layout_landing_page_id);

  const {
    response: { data: pages },
  } = useFetch(urlBuilder(`/workspaces/:workspace_id/pages`, { workspace_id: workspace._id }), { query: { sort: "ASC" } });

  useEffect(() => {
    if (workspace?._id) {
      setLayoutLandingPageId(workspace.layout_landing_page_id);
    }
    return () => {
      setLayoutLandingPageId(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace]);

  useEffect(() => {
    if (pages?.length > 0) {
      let sortedPages = pages;
      sortedPages.sort(function (a, b) {
        return a.workspace_id?.name.localeCompare(b.workspace_id?.name);
      });
      let navigationPages = sortedPages.map((page) => {
        return { value: page._id, key: page.name };
      });
      setLandingPageOptions(navigationPages);
    }
    return () => {};
  }, [pages]);

  return (
    <div className="w-full">
      <SelectMenu
        size="md"
        startIndex={landingPageOptions.findIndex((option) => option.value === layoutLandingPageId)}
        options={landingPageOptions || [{ key: "Select Page", value: -1 }]}
        setOption={(option) => {
          setLayoutLandingPageId(option.value);
          updateLayoutLandingPageId(option.value);
        }}
      />
    </div>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, { manageWorkspaceSettings })(LandingPageSelector);
