import { ClipboardDocumentCheckIcon, FunnelIcon, RectangleStackIcon, UserIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { getUserDetails } from "src/actions/user";
import { apiRequest } from "src/async/apiUtils";
import BackIndicator from "src/components/Navigation/BackIndicator";
import Tabs from "src/components/Shared/Tabs/Tabs";
import { H1 } from "src/components/Shared/Text/Headers";
import UserPermissions from "src/components/Users/Edit/Permissions/UserPermissions";
import UserProfileDetailsEdit from "src/components/Users/Edit/UserProfileDetailsEdit";
import UserWorkspaceList from "src/components/Users/Edit/Workspaces/UserWorkspaceList";
import UserProfile from "src/components/Users/UserProfile";
import { useLoadData } from "src/hooks/useLoadData";
import GlobalFiltersList from "src/components/Users/Edit/GlobalFilters/GlobalFiltersList";

const UserEdit = () => {
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [workspaces, setWorkspaces] = useState([])
  // const [workspaceGroupsLoaded, setWorkspaceGroupsLoaded] = useState(false)
  const [workspaceGroups, setWorkspaceGroups] = useState([])
  const [tabs, setTabs] = useState([]);

  useLoadData("post", "/users/details", { body: { id } }, (res) => setUser(res.data));

  // Load workspaces
  useEffect(() => {
    const loadWorkspaces = async () => {
      const res = await apiRequest('post', '/workspaces/list', {
        body: { includes: ['groups', 'users', 'pages'] }
      })

      const data = res.data

      if (data.status === 200) {
        setWorkspaces(data.data.map(item => ({  
          ...item, selected: user.workspaceIds.includes(item._id)
        })))
      }
    }

    if (user?._id) {
      setTabs([
        { 
          name: "View Profile", 
          icon: UserIcon, 
          component: (
            <UserProfileDetailsEdit workspaces={workspaces} groups={workspaceGroups} 
              setWorkspaces={setWorkspaces} setGroups={setWorkspaceGroups}
            /> 
          )
        },
        { name: "Workspaces", icon: RectangleStackIcon, component: <UserWorkspaceList userDetails={user} /> },
        { name: "Permissions", icon: ClipboardDocumentCheckIcon, component: <UserPermissions /> },
        {
          name: "Global Filters",
          icon: FunnelIcon,
          component: <GlobalFiltersList />,
        },
        // { name: "Settings", icon: Cog8ToothIcon, component: <div>Hello world</div> },
      ])
      loadWorkspaces()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ user?._id ])

  // Load groups
  // useEffect(() => {
  //   if (workspaces.length && user?._id) {
  //     const loadGroups = async () => {
  //       try {
  //         setWorkspaceGroupsLoaded(false)

  //         const res = await apiRequest(
  //           'post', 
  //           '/workspaces/domo-workspace-groups',
  //           { 
  //             body: { 
  //               workspaceIds: workspaces.map(item => item._id), 
  //               userId: user._id 
  //             } 
  //           }
  //         )

  //         const data = res.data

  //         if (data.status === 200) {
  //           setWorkspaceGroups(data.data)
  //         }

  //         setWorkspaceGroupsLoaded(true)

  //         // await props.getGroupData({}, ac.signal);
  //       } catch (error) {
  //         setWorkspaceGroupsLoaded(true)
  //       }
  //     };

  //     loadGroups()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [ workspaces.length, user?._id ])

  return (
    <>
      <H1 item={{ icon: BackIndicator }}>
        <p className="text-gray-300 font-light capitalize">{user?.type === "admin" ? "Admins" : "Users"} /</p>
        <div className="h-10 w-10 rounded-full overflow-hidden border border-gray-100">
          <UserProfile
            user={user}
            size="sm"
          />
        </div>
        {user?.name}
      </H1>
      <Tabs tabs={tabs}></Tabs>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userDetails,
    site: state.site,
  };
};

export default connect(mapStateToProps, { getUserDetails })(UserEdit);
