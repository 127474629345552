export const fetchData = async (method, host, body, signal = null, customHeaders = {}) => {
  let finalBody = body;

  if (!Array.isArray(body)) {
    finalBody = { ...body, token: localStorage.getItem("access_token") || body.token || null };
  }
  let signalParam = signal ? { signal } : {};
  return await fetch(host, {
    method,
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: finalBody.token,
      ...customHeaders,
    }),
    body: method !== "GET" && body ? JSON.stringify(finalBody) : null,
    ...signalParam,
  });
};
